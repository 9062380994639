import Accordion from "@mui/material/Accordion";
import { useState, useRef } from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SignaturePad from "react-signature-canvas";
import "./collapsableCard.css";
import { savefile, data, variables } from "../store/data";

interface Props {
  name: any;
  isadmin: boolean;
  content: any;
  css: string;
  contract: any;
  workspace: any;
  scrollFunc: any;
  scrollFuncRight: any;
}

const Card = ({
  name,
  content,
  css,
  isadmin,
  contract,
  workspace,
  scrollFunc,
  scrollFuncRight,
}: Props) => {
  let FCF = {
    firstCardFields: [
      {
        eid: "s1",
        display:
          "<h2><strong><br/><br/> Vertragspartner</strong></h2><br /><br />",
        hrTag: true,
        mainStatement: "Wer soll Vertragspartner werden?",
        statement: "Name Gesellschafter",
        type: "textfield",
        length: 1,
        textfieldapproval: false,
        dropselect_display: false,
        fields_display: true,
        ishelp: false,
        tiptapedit: false,
        editcontent: false,
        fields: [
          {
            eid: "1sts1-ms1",
            statement: "Name Gesellschafter",
            line: "Name Gesellschafter",
            value: "",
            size: "col-12",
          },
        ],
      },
      {
        eid: "s2",
        statement: "Straße, Hausnummer",
        type: "textfield",
        length: 1,
        textfieldapproval: false,
        dropselect_display: false,
        fields_display: true,
        ishelp: false,
        tiptapedit: false,
        editcontent: false,
        fields: [
          {
            eid: "s2-ms1",
            line: "Straße, Hausnummer",
            value: "",
            size: "col-12",
          },
        ],
      },
      {
        eid: "s3",
        statement: "PLZ, Ort",
        type: "textfield",
        length: 1,
        textfieldapproval: false,
        dropselect_display: false,
        fields_display: true,
        ishelp: false,
        tiptapedit: false,
        editcontent: false,
        fields: [
          {
            eid: "s3-ms1",
            line: "PLZ, Ort",
            value: "",
            size: "col-12",
          },
        ],
      },
    ],

    rightSection: [
      {
        if: true,
        type: "or",
        dependent_values: [
          {
            depend_upon: [],
            display: "<h2><strong>Vertragspartner</strong></h2>",
          },
        ],
        values_on_which_depend: [
          "s1-ms1",
          "s2-ms1",
          "s4-ms1",
          "s4-ms2",
          "s4-ms3",
          "s4-ms4",
          "s4-ms5",
          "s3-ms1",
        ],
        value: "",
        display: "",
        style: { textAlign: "center", fontWeight: "700", fontSize: "16px" },
        isStrong: true,
        afterlinebreak: 0,
        heading_property_number: true,
        heading_value: 0,
        tiptapedit: false,
        contentedit: false,
        inner_heading_property_number: false,
        inner_heading_value: 0,
        inner_heading_value_display: "",
      },
      {
        if: true,
        type: "or",
        dependent_values: [
          {
            depend_upon: ["s1-ms1"],
            display: "<br /><br /><strong> und </strong> <br /><br /> [[~~]]<br />",
          },
        ],
        values_on_which_depend: ["s1-ms1"],
        value: "",
        display: "",
        style: {
          wordBreak: "break-word",
          textAlign: "justify",
          marginTop: "0.5%",
        },
        isStrong: false,
        afterlinebreak: 0,
        heading_property_number: false,
        heading_value: 0,
        tiptapedit: false,
        contentedit: false,
        inner_heading_property_number: false,
        inner_heading_value: 0,
        inner_heading_value_display: "",
      },
      {
        if: true,
        type: "or",
        dependent_values: [
          {
            depend_upon: ["s2-ms1"],
            display: " [[~~]]<br />",
          },
        ],
        values_on_which_depend: ["s2-ms1"],
        value: "",
        display: "",
        style: {
          wordBreak: "break-word",
          textAlign: "justify",
          marginTop: "0.5%",
        },
        isStrong: false,
        afterlinebreak: 0,
        heading_property_number: false,
        heading_value: 0,
        tiptapedit: false,
        contentedit: false,
        inner_heading_property_number: false,
        inner_heading_value: 0,
        inner_heading_value_display: "",
      },
      {
        if: true,
        type: "or",
        dependent_values: [
          {
            depend_upon: ["s3-ms1"],
            display: " [[~~]] <br /> ",
          },
        ],
        values_on_which_depend: ["s3-ms1"],
        value: "",
        display: "",
        style: {
          wordBreak: "break-word",
          textAlign: "justify",
          marginTop: "0.5%",
        },
        isStrong: false,
        afterlinebreak: 0,
        heading_property_number: false,
        heading_value: 0,
        tiptapedit: false,
        contentedit: false,
        inner_heading_property_number: false,
        inner_heading_value: 0,
        inner_heading_value_display: "",
      },
    ],
  };

  /*************************************5th card additional field**************************** */

  let fifthCardAdditionalSections = {
    left: [
      {
        tiptapedit: false,
        dropselect_display: false,
        fields_display: true,
        editcontent: false,
        ishelp: false,
        eid: "c5s4",
        statement: "Bringt Gesellschafter Geld in bar in die Gesellschaft ein?",
        type: "radio",
        length: 2,
        value: null,
        newFieldsCreator: true,
        createFields: false,
        fields: [
          {
            tiptapedit: false,
            eid: "s3-ms1",
            value: "Ja",
            line: "Ja",
          },
          {
            tiptapedit: false,
            eid: "s3-ms2",
            value: "Nein",
            line: "Nein",
          },
        ],

        dependentFields: [
          {
            eid: "c5s4d1",
            mainStatement: "Wer soll Vertragspartner werden?",
            statement:
              "Wie viel Geld in bar bringt der Gesellschafter in die Gesellschaft ein?",
            type: "number",
            length: 1,
            textfieldapproval: false,
            dropselect_display: false,
            fields_display: true,
            ishelp: false,
            tiptapedit: false,
            editcontent: false,
            fields: [
              {
                eid: "s2-ms1",
                statement: "Name Gesellschafter",
                line: " ",
                value: "",
                size: "col-12",
              },
            ],
          },
        ],
      },

      {
        tiptapedit: false,
        dropselect_display: false,
        fields_display: true,
        editcontent: false,
        ishelp: false,
        eid: "c5s5",
        statement:
          "Bringt Gesellschafter materielle Werte in die Gesellschaft ein? ",
        type: "radio",
        length: 2,
        value: null,
        newFieldsCreator: true,
        createFields: false,
        fields: [
          {
            tiptapedit: false,
            eid: "s4-ms1",
            value: "Ja",
            line: "Ja",
          },
          {
            tiptapedit: false,
            eid: "s4-ms2",
            value: "Nein",
            line: "Nein",
          },
        ],

        dependentFields: [
          {
            eid: "c5s5d1",
            mainStatement: "Wer soll Vertragspartner werden?",
            statement:
              "Welche materielle Werte werden in die Gesellschaft eingebracht?",
            type: "textfield",
            length: 1,
            textfieldapproval: false,
            dropselect_display: false,
            fields_display: true,
            ishelp: false,
            tiptapedit: false,
            editcontent: false,
            fields: [
              {
                eid: "s3-ms1",
                statement: "Name Gesellschafter",
                line: " ",
                value: "",
                size: "col-12",
              },
            ],
          },
          {
            eid: "c5s5d2",
            mainStatement: "Wer soll Vertragspartner werden?",
            statement:
              "Wie hoch ist der finanzielle Wert der materiellen Werte?",
            type: "number",
            length: 1,
            textfieldapproval: false,
            dropselect_display: false,
            fields_display: true,
            ishelp: false,
            tiptapedit: false,
            editcontent: false,
            fields: [
              {
                eid: "s3-ms2",
                statement: "Name Gesellschafter",
                line: " ",
                value: "",
                size: "col-12",
              },
            ],
          },
        ],
      },

      {
        textfieldapproval: false,
        dropselect_display: false,
        editcontent: false,
        fields_display: true,
        ishelp: false,
        tiptapedit: false,
        eid: "c5s6",
        statement:
          "In welcher Höhe ist Gesellschafter Output: Input Field  2 am Gesellschaftsvermögen am beteiligt?",
        type: "number",
        length: 1,
        fields: [
          {
            eid: "s9-ms1",
            line: "",
            value: "",
            size: "col-12",
          },
        ],
      },
    ],

    right: [
      {
        if: true,
        type: "or",
        dependent_values: [
          {
            depend_upon: ["c5s4d1"],
            display: "bringt in bar [[~~]] € in die Gesellschaft ein. <br />",
          },
        ],
        values_on_which_depend: [
          {
            s3: "Ja",
          },
        ],
        value: "",
        display: "",
        style: {
          wordBreak: "break-word",
          textAlign: "justify",
          marginTop: "0.5%",
          display: "inline",
        },
        isStrong: false,
        afterlinebreak: 1,
        heading_property_number: false,
        heading_value: 0,
        inner_heading_property_number: true,
        inner_heading_value: 0,
        inner_heading_value_display: "",
        displayed: false,
        tiptapedit: false,
        contentedit: false,
      },
      {
        if: true,
        type: "or",
        dependent_values: [
          {
            depend_upon: ["c5s5d1"],
            display: "bringt [[~~]] im Wert von ",
          },
        ],
        values_on_which_depend: [
          {
            s4: "Ja",
          },
        ],
        value: "",
        display: "",
        style: {
          wordBreak: "break-word",
          textAlign: "justify",
          marginTop: "0.5%",
          display: "inline",
        },
        isStrong: false,
        afterlinebreak: 1,
        heading_property_number: false,
        heading_value: 0,
        inner_heading_property_number: true,
        inner_heading_value: 0,
        inner_heading_value_display: "",
        displayed: false,
        tiptapedit: false,
        contentedit: false,
      },
      {
        if: true,
        type: "or",
        dependent_values: [
          {
            depend_upon: ["c5s5d2"],
            display: "[[~~]] € ein.<br /><br />",
          },
        ],
        values_on_which_depend: [
          {
            s4: "Ja",
          },
        ],
        value: "",
        display: "",
        style: {
          wordBreak: "break-word",
          textAlign: "justify",
          marginTop: "0.5%",
          display: "inline",
        },
        isStrong: false,
        afterlinebreak: 1,
        heading_property_number: false,
        heading_value: 0,
        inner_heading_property_number: true,
        inner_heading_value: 0,
        inner_heading_value_display: "",
        displayed: false,
        tiptapedit: false,
        contentedit: false,
      },
      {
        if: true,
        type: "or",
        dependent_values: [
          {
            depend_upon: ["c5s6"],
            display:
              " Gesellschafter ist in Höhe von [[~~]] € am Gesellschaftsvermögen am beteiligt.<br /><br />",
          },
        ],
        values_on_which_depend: ["s9-ms1"],
        value: "",
        display: "",
        style: {
          wordBreak: "break-word",
          textAlign: "justify",
          marginTop: "0.5%",
          display: "inline",
        },
        isStrong: false,
        afterlinebreak: 1,
        heading_property_number: false,
        heading_value: 0,
        inner_heading_property_number: true,
        inner_heading_value: 0,
        inner_heading_value_display: "",
        displayed: false,
        tiptapedit: false,
        contentedit: false,
      },
    ],
  };

  /*************************************end 5th card additional field**************************** */

  let cardOfDate = {
    left: [
      {
        eid: "c13s3",
        mainStatement: "Gesellschafter",
        statement:
          "Bitte geben Sie den Ort ein wo der Vertrag unterzeichnet wird:",
        type: "textfield",
        length: 1,
        textfieldapproval: false,
        dropselect_display: false,
        fields_display: true,
        ishelp: false,
        tiptapedit: false,
        editcontent: false,
        fields: [
          {
            eid: "s4-ms1",
            line: "",
            value: "",
            size: "col-12",
          },
        ],
      },
      {
        eid: "c13s4",
        statement: "Bitte geben Sie das aktuelle Datum ein:",
        type: "date",
        length: 1,
        textfieldapproval: false,
        dropselect_display: false,
        fields_display: true,
        ishelp: false,
        tiptapedit: false,
        editcontent: false,
        fields: [
          {
            eid: "s5-ms1",
            line: "",
            value: "",
            size: "col-12",
          },
        ],
      },
    ],

    right: [
      {
        if: true,
        type: "or",
        dependent_values: [
          {
            depend_upon: ["c13s3"],
            display: "<br />[[~~]] <br />",
          },
        ],
        values_on_which_depend: ["s4-ms1"],
        value: "",
        display: "",
        // style: {
        //   wordBreak: "break-word",
        //   textAlign: "center",
        //   fontWeight: "700",
        //   fontSize: "18.72px",
        // },
        isStrong: true,
        afterlinebreak: 0,

        heading_property_number: false,
        heading_value: 0,
        displayed: false,
        tiptapedit: false,
        contentedit: false,
        inner_heading_property_number: false,
        inner_heading_value: 0,
        inner_heading_value_display: "",
      },
      {
        if: true,
        type: "or",
        dependent_values: [
          {
            depend_upon: ["c13s4"],
            display:
              "[[~~]]        Unterschrift _________________________________<br /> ",
          },
        ],
        values_on_which_depend: ["s5-ms1"],
        value: "",
        display: "",
        // style: {
        //   wordBreak: "break-word",
        //   textAlign: "center",
        //   fontWeight: "700",
        //   fontSize: "18.72px",
        // },
        isStrong: true,
        afterlinebreak: 0,
        heading_property_number: false,
        heading_value: 0,
        displayed: false,
        tiptapedit: false,
        contentedit: false,
        inner_heading_property_number: false,
        inner_heading_value: 0,
        inner_heading_value_display: "",
      },
    ],
  };

  let Total = 0;

  var cont1 = content;
  var field1 = content.section[0];
  let expand1 = false;
  let id = data.renameID;
  const [expand, updateExpand] = useState(expand1);
  const [field, updateField] = useState(field1);
  const [cont, updateContent] = useState(cont1);
  const [ID, updateID] = useState(id);
  const [showFields, setFields] = useState(false);
  const [shadow, setShadow] = useState(false);
  const [expanded, setExpanded] = useState<string | false>(false);

  /*******************zahid****************************** */
  const handleDate = (event: any, id_of: any) => {
    const dateee = event.target.value.toString();
    // console.log("String date", dateee);
    const myarray = dateee.split("-");
    // console.log("myarray......", myarray);
    const myyear = myarray[0];
    const mymonth = myarray[1];
    const myday = myarray[2];

    const mydate = myday + "." + mymonth + "." + myyear;

    // console.log("mydate.......", mydate);

    //data1=localstorage Data
    //d1=card right having date

    // let data = JSON.parse(localStorage.getItem("Data") || "{}");
    let cc = data1.workspace
      .filter((g: any) => g.id === workspace.id)[0]
      .contracts.filter((d: any) => d.id === contract[0].id)[0];
    let leftttttt = cc.document.leftSide.card;
    let righttttt = cc.document.rightSide.card;

    // console.log("rightttttttttttttttttttttttttt", id_of);

    let cardNo = id_of.substr(1, id_of.indexOf("s"));
    let sectionNo = id_of.substr(id_of.indexOf("s") + 1);

    cardNo = parseInt(cardNo);
    sectionNo = parseInt(sectionNo);

    if (cardNo !== 7 && id_of !== "c6s4") {
      righttttt[cardNo - 1].section[0].displayed = true;
    }

    let currentSection = leftttttt.filter((d: any) => d.id === cardNo)[0]
      .section[sectionNo - 1];

    currentSection.value = mydate;

    /******************setting value of input-1 from Vertragspartner into the later card statement********  */

    //5th card

    let right = cc.document.rightSide.card.filter(
      (rc: any) => rc.name === cont.name
    );

    let eid = field.fields[0].eid;

    // console.log("eid", eid)
    if (right[0].eye === false) {
      handleVisibility(right[0]);
    }

    let currentRightSection = cc.document.rightSide.card
      .filter((d: any) => d.id === cardNo)[0]
      .section.filter(
        (d: any) => d.dependent_values[0].depend_upon == id_of
      )[0];

    if (cardNo !== 7 && id_of !== "c6s4") {
      currentRightSection.displayed = true;
    }

    let currentDisplay = currentRightSection.dependent_values[0].display;

    var indices = [];
    // var array = ['a', 'b', 'a', 'c', 'a', 'd'];
    var element = "[[";
    var idx = currentDisplay.indexOf(element);
    while (idx != -1) {
      indices.push(idx);
      idx = currentDisplay.indexOf(element, idx + 1);
    }
    // [0, 2, 4]

    indices.forEach((i: any) => {
      let startIndexOfTexttoReplace = i + 2;
      let endIndexOfTexttoReplace = currentDisplay.indexOf("~~");

      // currentDisplay.splice(startIndexOfTexttoReplace,0,currentSection.value)

      let cardNo2 = currentDisplay.substr(
        endIndexOfTexttoReplace + 3,
        currentDisplay.indexOf("s")
      );
      let sectionNo2 = currentDisplay.substr(
        currentDisplay.indexOf("s", currentDisplay.indexOf("~")) + 1,
        currentDisplay.indexOf("]") -
          (currentDisplay.indexOf("s", currentDisplay.indexOf("~")) + 1)
      );

      cardNo2 = parseInt(cardNo2);
      // sectionNo2 = parseInt(sectionNo2)

      let sectionWithDesiredValue = leftttttt.filter(
        (d: any) => d.id === cardNo2
      )[0].section[sectionNo2 - 1];

      currentDisplay =
        currentDisplay.slice(0, startIndexOfTexttoReplace) +
        sectionWithDesiredValue.value +
        currentDisplay.slice(endIndexOfTexttoReplace);
      currentRightSection.dependent_values[0].display = currentDisplay;
    });

    data1.workspace
      .filter((g: any) => g.id === workspace.id)[0]
      .contracts.filter((d: any) => d.id === contract[0].id)[0] = cc;

    savefile(data1);
  };
  /*******************************************end **************************** */

  // const handleAccordion =
  //   (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
  //     setExpanded(isExpanded ? panel : false);
  //     // console.log("isExpanded......", isExpanded);
  //     if (isExpanded === true) {
  //       cc.document.rightSide.card[0].isClicked = true;
  //       // console.log("border  expanded",  cc.document.rightSide.card[0].isClicked)
  //       // console.log("if wala data1",data1)
  //       localStorage.setItem("Data", JSON.stringify(data1));
  //     }
  //     // cc.document.rightSide.card[0].isClicked = false;
  //     // localStorage.setItem("Data", JSON.stringify(data1));
  //     else {
  //       cc.document.rightSide.card[0].isClicked = false;
  //       // console.log("border un ex expanded",  cc.document.rightSide.card[0].isClicked)
  //       // console.log("if else wala data1",data1)

  //       localStorage.setItem("Data", JSON.stringify(data1));
  //     }
  //   };


  const handleAccordion =
  (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);

  };

  let data1 = JSON.parse(localStorage.getItem("Data") || "{}");
  let cc = data1.workspace
    .filter((g: any) => g.id === workspace.id)[0]
    .contracts.filter((d: any) => d.id === contract[0].id)[0];
  let border = cc.document.rightSide.card[0].isClicked;
  // console.log("border........",border);
  // cc.document.rightSide.card[0].isClicked = false;
  // localStorage.setItem("Data", JSON.stringify(data1));

  //for signature
  const [canvasImg, setcanvasImg] = useState({ trimmedDataURL: null });

  const Canvas = useRef() as React.MutableRefObject<any>;
  const clear = () => {
    Canvas.current.clear();
  };

  let d1 = data1.workspace
    .filter((g: any) => g.id === workspace.id)[0]
    .contracts.filter((c: any) => c.id === contract[0].id)[0]
    .document.rightSide.card.filter((rc: any) => {
      return (
        rc.name === "Unterschrift" || rc.name === "Änderungen des Vertrages"
      );
    })[0];

  let cards = data1.workspace
    .filter((g: any) => g.id === workspace.id)[0]
    .contracts.filter((c: any) => c.id === contract[0].id)[0]
    .document.leftSide.card.filter((c: any) => c.id === content.id)[0].section;

  Total = cards.reduce(
    (partialSum: any, a: any) =>
      partialSum +
      (a.count || 0) +
      (a.createFields
        ? a.dependentFields.reduce(
            (partialSum: any, a: any) => partialSum + (a.count || 0),
            0
          )
        : 0),
    0
  );

  let d2 = contract[0].document.leftSide.card.map((a: any) => a.name);
  var name_contract = "GbR Vertag";

  const Trim = () => {
    const saveSig = Canvas.current.getTrimmedCanvas().toDataURL("image/png");
    d1.section[0].image = saveSig;
    localStorage.setItem("imageU", saveSig);
    data.image = saveSig;
    d1.section[0].displayed = true;
    d1.section.displayed = true;
    localStorage.setItem("Data", JSON.stringify(data1));
  };

  const handleName = (e: any) => {
    d1.eye = true;
    d1.section.displayed = true;
    d1.section[0].displayed = true;
    d1.section[0].name = e.target.value;
    localStorage.setItem("USERN", e.target.value);
    localStorage.setItem("Data", JSON.stringify(data1));
  };

  const handleName1 = (e: any) => {
    d1.eye = true;
    d1.section.displayed = true;
    d1.section[0].displayed = true;
    d1.section[0].name1 = e.target.value;
    localStorage.setItem("USERN1", e.target.value);
    localStorage.setItem("Data", JSON.stringify(data1));
  };

  const handleDate1 = (event: any) => {
    d1.eye = true;
    d1.section.displayed = true;
    d1.section[0].displayed = true;
    d1.section.name1 = localStorage.getItem("USERN");
    d1.section[0].date1 = event.target.value;
    localStorage.setItem("USERD1", event.target.value);
    localStorage.setItem("Data", JSON.stringify(data1));
  };

  const handleVisibility = (c: any) => {
    c.eye = !c.eye;
    let left = contract[0].document.leftSide.card.filter(
      (lc: any) => lc.name === c.name
    );
    let right = contract[0].document.rightSide.card.filter(
      (rc: any) => rc.name === c.name
    );
    right[0].eye = c.eye;
    left[0].eye = c.eye;
    let data = JSON.parse(localStorage.getItem("Data") || "{}");
    data.workspace
      .filter((g: any) => g.id === workspace.id)[0]
      .contracts.filter(
        (d: any) => d.id === contract[0].id
      )[0].document.rightSide.card = contract[0].document.rightSide.card;
    data.workspace
      .filter((g: any) => g.id === workspace.id)[0]
      .contracts.filter(
        (d: any) => d.id === contract[0].id
      )[0].document.leftSide.card = contract[0].document.leftSide.card;

    localStorage.setItem("Data", JSON.stringify(data));
  };


  const handleTextInput = async (e: any, id_of: any) => {
    // let data = JSON.parse(localStorage.getItem("Data") || "{}");
    let cc = data1.workspace
      .filter((g: any) => g.id === workspace.id)[0]
      .contracts.filter((d: any) => d.id === contract[0].id)[0];
    let leftttttt = cc.document.leftSide.card;
    let righttttt = cc.document.rightSide.card;

    // console.log("rightttttttttttttttttttttttttt", righttttt);

    let cardNo = id_of.substr(1, id_of.indexOf("s"));
    let sectionNo = id_of.substr(id_of.indexOf("s") + 1);

    cardNo = parseInt(cardNo);
    sectionNo = parseInt(sectionNo);

    // cc.document.rightSide.card.filter((a:any)=>(a.id==cardNo))[0].isClicked = true;
    // localStorage.setItem("Data", JSON.stringify(data1));

    if (cardNo !== 7 && id_of !== "c6s4") {
      righttttt[cardNo - 1].section[0].displayed = true;
    }

    let currentSection = leftttttt.filter((d: any) => d.id === cardNo)[0]
      .section[sectionNo - 1];

    currentSection.value = e.target.value;

    /******************setting value of input-1 from Vertragspartner into the later card statement********  */

    if (id_of === "1sts1-ms1") {
      leftttttt[4].section[0].statement = `Bringt Gesellschafter ${e.target.value} Geld in bar in die Gesellschaft ein?`;
    }

    //5th card

    if (id_of.indexOf("c1s") > -1) {
      let num = id_of.substring(3);
      num = parseInt(num);

      // left side

      if (num % 3 === 1 || num === 1) {
        leftttttt[4].section[
          num - 1
        ].statement = `Bringt Gesellschafter ${e.target.value} Geld in bar in die Gesellschaft ein?`;
        // console.log("left 4.........", leftttttt);

        leftttttt[4].section[
          num
        ].statement = `Bringt Gesellschafter ${e.target.value} materielle Werte in die Gesellschaft ein?`;
        // console.log("left 4.........", leftttttt);

        leftttttt[4].section[
          num + 1
        ].statement = `In welcher Höhe ist Gesellschafter ${e.target.value} am Gesellschaftsvermögen am beteiligt?`;
        // console.log("left 4.........", leftttttt);

        //right side
        let added = num / 3;
        added = Math.trunc(added);

        righttttt[4].section[
          num + added
        ].dependent_values[0].display = `  ${e.target.value}  bringt in bar [[~~c5s${num}d1]] € in die Gesellschaft ein. <br/>`;
        // console.log("left 4.........", leftttttt);

        righttttt[4].section[
          num + 1 + added
        ].dependent_values[0].display = `  ${e.target.value}  bringt [[~~c5s${num + 1
        }d1]] im Wert von  `;
        // console.log("left 4.........", leftttttt);

        righttttt[4].section[
          num + 3 + added
        ].dependent_values[0].display = `  Gesellschafter ${e.target.value
        }  ist in Höhe von [[~~c5s${num + 2
          }]] € am Gesellschaftsvermögen am beteiligt. <br/><br/>`;
        // console.log("left 4.........", leftttttt);
        // console.log("rigjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj", righttttt[4]);

        // card # 13

        leftttttt[12].section[
          ((num - 1) / 3) * 2
        ].mainStatement = `Gesellschafter ${e.target.value}`;

        righttttt[12].section[
          ((num + 2) / 3) * 2
        ].dependent_values[0].display = `  [[~~c13s${((num - 1) / 3) * 2 + 2
        }]] <br/> ${e.target.value} Unterschrift ______________<br/>`;
      }
    }

    // console.log("rigjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj", righttttt[4]);

    /************************ end setting.....*******************************/

    ///////end of card 5

    /************************ end setting.....*******************************/

    let right = cc.document.rightSide.card.filter(
      (rc: any) => rc.name === cont.name
    );

    let eid = field.fields[0].eid;

    // console.log("eid", eid)
    if (right[0].eye === false) {
      handleVisibility(right[0]);
    }

    let currentRightSection = cc.document.rightSide.card
      .filter((d: any) => d.id === cardNo)[0]
      .section.filter(
        (d: any) => d.dependent_values[0].depend_upon == id_of
      )[0];

    if (cardNo !== 7 && id_of !== "c6s4") {
      currentRightSection.displayed = true;
    }

    let currentDisplay = currentRightSection.dependent_values[0].display;

    var indices = [];
    // var array = ['a', 'b', 'a', 'c', 'a', 'd'];
    var element = "[[";
    var idx = currentDisplay.indexOf(element);
    while (idx != -1) {
      indices.push(idx);
      idx = currentDisplay.indexOf(element, idx + 1);
    }
    // [0, 2, 4]

    indices.forEach((i: any) => {
      let startIndexOfTexttoReplace = i + 2;
      let endIndexOfTexttoReplace = currentDisplay.indexOf("~~");

      // currentDisplay.splice(startIndexOfTexttoReplace,0,currentSection.value)

      let cardNo2 = currentDisplay.substr(
        endIndexOfTexttoReplace + 3,
        currentDisplay.indexOf("s")
      );
      let sectionNo2 = currentDisplay.substr(
        currentDisplay.indexOf("s", currentDisplay.indexOf("~")) + 1,
        currentDisplay.indexOf("]") -
        (currentDisplay.indexOf("s", currentDisplay.indexOf("~")) + 1)
      );

      cardNo2 = parseInt(cardNo2);
      // sectionNo2 = parseInt(sectionNo2)

      let sectionWithDesiredValue = leftttttt.filter(
        (d: any) => d.id === cardNo2
      )[0].section[sectionNo2 - 1];

      currentDisplay =
        currentDisplay.slice(0, startIndexOfTexttoReplace) +
        sectionWithDesiredValue.value +
        currentDisplay.slice(endIndexOfTexttoReplace);
      currentRightSection.dependent_values[0].display = currentDisplay;
    });

    data1.workspace
      .filter((g: any) => g.id === workspace.id)[0]
      .contracts.filter((d: any) => d.id === contract[0].id)[0] = cc;

    savefile(data1);
  };

  /********************************handle text input for dependent fields*********************** */

  const handleTextInputforDependentFields = async (e: any, id_of: any) => {
    // let data = JSON.parse(localStorage.getItem("Data") || "{}");
    let cc = data1.workspace
      .filter((g: any) => g.id === workspace.id)[0]
      .contracts.filter((d: any) => d.id === contract[0].id)[0];
    let leftttttt = cc.document.leftSide.card;

    let cardNo = id_of.substr(1, id_of.indexOf("s") - 1);
    let sectionNo = id_of.substr(
      id_of.indexOf("s") + 1,
      id_of.indexOf("d") - id_of.indexOf("s") - 1
    );
    let depNo = id_of.substr(id_of.indexOf("d") + 1);

    // console.log("idodfffffffffffffffffff", id_of);

    cardNo = parseInt(cardNo);
    sectionNo = parseInt(sectionNo);
    depNo = parseInt(depNo);

    let currentSection = leftttttt.filter((d: any) => d.id === cardNo)[0]
      .section[sectionNo - 1].dependentFields;
    let depEid = currentSection[0].eid;
    let dependentFieldNo = depEid.substr(depEid.indexOf("d") + 1);

    currentSection[depNo - 1].value = e.target.value;
    // console.log(
    //   "currentsec!!!!!!!!!!!!###############",
    //   currentSection[depNo - 1]
    // );

    let right = cc.document.rightSide.card.filter(
      (rc: any) => rc.name === cont.name
    );

    let eid = field.fields[0].eid;

    // console.log("eidddddddd", depEid);

    // console.log("eid", eid)
    if (right[0].eye === false) {
      handleVisibility(right[0]);
    }

    let currentRightSection = cc.document.rightSide.card
      .filter((d: any) => d.id === cardNo)[0]
      .section.filter(
        (d: any) => d.dependent_values[0].depend_upon == id_of
      )[0];
    currentRightSection.displayed = true;

    // console.log("rightttttttttttttname", currentRightSection);

    let currentDisplay = currentRightSection.dependent_values[0].display;

    var indices = [];
    // var array = ['a', 'b', 'a', 'c', 'a', 'd'];
    var element = "[[";
    var idx = currentDisplay.indexOf(element);
    while (idx != -1) {
      indices.push(idx);
      idx = currentDisplay.indexOf(element, idx + 1);
    }
    // console.log("indicessssssssssss", indices);
    // [0, 2, 4]

    let idOfDesiredSection =
      currentRightSection.dependent_values[0].depend_upon[0];
    // console.log(
    //   "inddddddddddddddddddddddddddddddddddddddddddddd",
    //   idOfDesiredSection
    // );

    let cardNo2 = idOfDesiredSection.substr(
      1,
      idOfDesiredSection.indexOf("s") - 1
    );
    let sectionNo2 = idOfDesiredSection.substr(
      idOfDesiredSection.indexOf("s") + 1,
      idOfDesiredSection.indexOf("d") - idOfDesiredSection.indexOf("s") - 1
    );
    let depNo2 = idOfDesiredSection.substr(idOfDesiredSection.indexOf("d") + 1);

    indices.forEach((i: any) => {
      let startIndexOfTexttoReplace = i + 2;
      let endIndexOfTexttoReplace = currentDisplay.indexOf("~~");

      // // currentDisplay.splice(startIndexOfTexttoReplace,0,currentSection.value)

      // console.log("currentDisplay", currentDisplay)
      // console.log("endindexoftexttoreplace", endIndexOfTexttoReplace)

      // let cardNo2 = currentDisplay.substr(endIndexOfTexttoReplace + 3, currentDisplay.indexOf('s'))
      // console.log("currenntdisssssssssssss", currentDisplay)
      // let sectionNo2 = currentDisplay.substr(currentDisplay.indexOf('s', currentDisplay.indexOf('~')) + 1, (currentDisplay.indexOf('d') - (currentDisplay.indexOf('s', currentDisplay.indexOf('~')) + 1)))

      // // cardNo2 = parseInt(cardNo2)
      // // sectionNo2 = parseInt(sectionNo2)
      // console.log("cardnooooooo", cardNo2);
      // console.log("sectionNOoooooooo", sectionNo2);

      // console.log("dependent field no", depNo2);
      cardNo2 = parseInt(cardNo2);
      sectionNo2 = parseInt(sectionNo2);
      depNo2 = parseInt(depNo2);
      let sectionWithDesiredValue = leftttttt.filter(
        (d: any) => d.id === cardNo2
      )[0].section[sectionNo2 - 1].dependentFields[depNo2 - 1];

      // console.log("section with desired value", sectionWithDesiredValue);
      currentDisplay =
        currentDisplay.slice(0, startIndexOfTexttoReplace) +
        sectionWithDesiredValue.value +
        currentDisplay.slice(endIndexOfTexttoReplace);
      currentRightSection.dependent_values[0].display = currentDisplay;
      // console.log("currentRightSection", right[0]);
    });

    data1.workspace
      .filter((g: any) => g.id === workspace.id)[0]
      .contracts.filter((d: any) => d.id === contract[0].id)[0] = cc;

    savefile(data1);
  };

  /*******************************end of handle...for dependent fields**************************** */

  const section = (a: any, b: any, c: boolean) => {
    // a= current card
    // b=current section

    if (c) {
      b.displayed = true;

      let var1 = contract[0].document.rightSide.card
        .filter((rc: any) => rc.name === a.name)[0]
        .section.map((card: any) => card.dependent_values)
        .map((v: any) => v[0]);
      let var2 = var1.filter(
        (v2: any) => v2.depend_upon[0] === b.fields[0].eid
      )[0];
      let var3 = var2?.display;
      if (var3 != undefined) {
        let startIndex = var3.indexOf("[[");
        startIndex = startIndex + 2;
        let endIndex = var3.indexOf("0]]");
        let var4 = var3.substring(startIndex, endIndex);
        return var4;
      } else {
        return "";
      }
    }

    if (a.eye === false) {
      handleVisibility(a);
    }
    updateField(b);
    updateContent(a);
  };
  function handleCardRename(I: any) {
    let data = JSON.parse(localStorage.getItem("Data") || "{}");
    let cc = data.workspace
      .filter((g: any) => g.id === workspace.id)[0]
      .contracts.filter((d: any) => d.id === contract[0].id)[0];
    for (let i = 0; i < cc.document.leftSide.card.length; i++) {
      cc.document.leftSide.card[i].name_edit = false;
    }
    let left = cc.document.leftSide.card.filter((rc: any) => rc.id === I);
    left[0].name_edit = true;
    savefile(data);
    document.getElementById("standard-basic")?.click();
    updateContent(left[0]);
  }
  const handleSave = (e: any) => {
    let a = e.target.value;
    if (e.key === "Enter") {
      let data = JSON.parse(localStorage.getItem("Data") || "{}");
      let cc = data.workspace
        .filter((g: any) => g.id === workspace.id)[0]
        .contracts.filter((d: any) => d.id === contract[0].id)[0];
      let left = cc.document.leftSide.card.filter(
        (rc: any) => rc.name === cont.name
      );
      let right = cc.document.rightSide.card.filter(
        (rc: any) => rc.name === cont.name
      );
      if (a != "") {
        right[0].name = a;
        left[0].name = a;
      }
      left[0].name_edit = false;
      localStorage.setItem("Data", JSON.stringify(data));
      updateContent(left[0]);
    }
  };
  const handleCardExpansion = (content: any) => {

    let accord_card = document.getElementById("card");


    setShadow(!shadow);

    if (!content.name_edit || !isadmin) {
      updateExpand(!expand);
      data1.drag = !expand;
      savefile(data1);
    }

    if (content.name_edit && isadmin) {
      updateExpand(false);
    }

    localStorage.setItem("card_clicked", content.name);

    if (content.eye === false && !expand) {
      handleVisibility(content);
    }


    let dataInCardExpansion = JSON.parse(localStorage.getItem("Data") || "{}");
    let ccInCardExpansion = dataInCardExpansion.workspace
      .filter((g: any) => g.id === workspace.id)[0]
      .contracts.filter((d: any) => d.id === contract[0].id)[0];



    if (expand == false) {
      ccInCardExpansion.document.rightSide.card.filter((a: any) => a.id === content.id)[0].isClicked = true;
      localStorage.setItem("Data", JSON.stringify(dataInCardExpansion));
    }
    else {
      ccInCardExpansion.document.rightSide.card.filter((a: any) => a.id === content.id)[0].isClicked = false;
      localStorage.setItem("Data", JSON.stringify(dataInCardExpansion));
    }


  



  };

  const handleUpdateRenameID = (a: number) => {
    data.renameID = a;
    updateID(data.renameID);
  };
  const handleSelect = (e: any) => {
    variables.choice = e.target.value;
    updateExpand(expand);
    // if(e.target.value==="")
  };

  const handleRadioClick = (o: any, o1: any, o2: any) => {


    // o = Ja/Nein
    // o1 = section
    // o2 = Card


    let data = JSON.parse(localStorage.getItem("Data") || "{}");
    let cc = data.workspace
      .filter((g: any) => g.name === workspace.name)[0]
      .contracts.filter((d: any) => d.id === contract[0].id)[0];
    let card = cc.document.rightSide.card.filter(
      (a: any) => a.name === o2.name
    )[0].section;

    let right = cc.document.rightSide.card.filter(
      (rc: any) => rc.name === cont.name
    );

    if (right[0].eye === false) {
      handleVisibility(right[0]);
    }
    right[0].section[0].displayed = true;

    let currentCard = cc.document.leftSide.card.filter(
      (a: any) => a.id === o2.id
    )[0];
    let currentSection = currentCard.section.filter(
      (a: any) => a.eid === o1.eid
    )[0];



    if (
      o2.name !== "Vertragspartner" &&
      o2.id !== 5 &&
      o2.id !== 7 &&
      o1.eid !== "c6s3"
    ) {
      if (o == "Ja") {
        let desiredRightSection = right[0].section.filter(
          (d: any) => d.dependent_values[0].depend_upon == o1.eid
        );
        desiredRightSection[0].displayed = true;
        if (desiredRightSection[1]) {
          desiredRightSection[1].displayed = false;
        }

        currentSection.fields.filter((fd:any)=>fd.value==="Ja")[0].checked=true
        if (currentSection.fields.filter((fd:any)=>fd.value==="Nein")[0]){currentSection.fields.filter((fd:any)=>fd.value==="Nein")[0].checked=false}



        // console.log("desiredsection.////", desiredRightSection[0]);
      }
      if (o == "Nein") {
        let desiredRightSection = right[0].section.filter(
          (d: any) => d.dependent_values[0].depend_upon == o1.eid
        );
        if (desiredRightSection[1]) {
          desiredRightSection[1].displayed = true;
        }
        desiredRightSection[0].displayed = false;


        currentSection.fields.filter((fd:any)=>fd.value==="Nein")[0].checked=true
        currentSection.fields.filter((fd:any)=>fd.value==="Ja")[0].checked=false

        // console.log("desiredsection.////", desiredRightSection[1]);
      }
    }



    if (name === "Vertragspartner") {
      let desiredCard = cc.document.leftSide.card.filter(
        (a: any) => a.id === 1
      )[0];

      let desiredRightCard = cc.document.rightSide.card.filter(
        (a: any) => a.id === 1
      )[0];
      let desiredRightSection = desiredRightCard.section;

      // for fifth card

      let fifthCardLeftSections = cc.document.leftSide.card.filter(
        (a: any) => a.id === 5
      )[0].section;

      let fifthCardRightSections = cc.document.rightSide.card.filter(
        (a: any) => a.id === 5
      )[0].section;

      //for card of date

      let dateCardLeftSections = cc.document.leftSide.card.filter(
        (a: any) => a.id === 13
      )[0].section;

      let dateCardRightSections = cc.document.rightSide.card.filter(
        (a: any) => a.id === 13
      )[0].section;

      if (o === "Ja") {
        let av = desiredCard.section;
        
        for (var i = 0; i < 3; i++) {
          av.splice(
             av.length -2 ,
            0,
            JSON.parse(JSON.stringify(FCF.firstCardFields[i]))
          );
        }
        for (var i = 3; i < av.length - 2; i++) {
          av[i].eid = `c1s${i + 1}`;
          av[i].fields[0].eid = `s${i + 1}-ms1`;
        }

        //.............

        //right section
        let startPlace: number = desiredRightSection.length;
        let ais = 1;

        for (var i = startPlace; i < startPlace + 3; i++) {
          desiredRightSection.splice(
            i,
            0,
            JSON.parse(JSON.stringify(FCF.rightSection[ais]))
          );
          ais = ais + 1;
        }
        for (var i = 4; i < desiredRightSection.length; i++) {
          // desiredRightSection[i].values_on_which_depend=`s${i+1}-ms1`
          desiredRightSection[i].dependent_values[0].depend_upon[0] = `c1s${i}`;
          desiredRightSection[i].dependent_values[0].display = [
            desiredRightSection[i].dependent_values[0].display.slice(
              0,
              desiredRightSection[i].dependent_values[0].display.indexOf("~~") +
              2
            ),
            `c1s${i}`,
            desiredRightSection[i].dependent_values[0].display.slice(
              desiredRightSection[i].dependent_values[0].display.indexOf("]]")
            ),
          ].join("");
          desiredRightSection[i].values_on_which_depend[0] = `s${i}-ms1`;
        }

        // for fifth card

        let eidCorrectedAdditionalSectionsLeft = JSON.parse(
          JSON.stringify(fifthCardAdditionalSections.left)
        );
        eidCorrectedAdditionalSectionsLeft.forEach(
          (element: any, index: any) => {
            element.eid = `c5s${o2.section.length - 1 + index}`;
            if (element.dependentFields) {
              element.dependentFields.forEach((ed: any, indexd: any) => {
                ed.eid = `c5s${o2.section.length - 1 + index}d${indexd + 1}`;
              });
            }
          }
        );

        let eidCorrectedAdditionalSectionsRight = JSON.parse(
          JSON.stringify(fifthCardAdditionalSections.right)
        );
        eidCorrectedAdditionalSectionsRight.forEach(
          (element: any, index: any) => {
            if (index === 0) {
              element.dependent_values[0].depend_upon[0] = `c5s${o2.section.length - 1 + index
                }d1`;
            }

            if (index === 1) {
              element.dependent_values[0].depend_upon[0] = `c5s${o2.section.length - 1 + index
                }d1`;
            }

            if (index === 2) {
              element.dependent_values[0].depend_upon[0] = `c5s${o2.section.length - 2 + index
                }d2`;
            }

            if (index === 3) {
              element.dependent_values[0].depend_upon[0] = `c5s${o2.section.length - 2 + index
                }`;
            }

            let display =
              element.dependent_values[0].display.slice(
                0,
                element.dependent_values[0].display.indexOf("~") + 2
              ) +
              element.dependent_values[0].depend_upon[0] +
              element.dependent_values[0].display.slice(
                element.dependent_values[0].display.indexOf("]")
              );
            element.dependent_values[0].display = display;
          }
        );

        // console.log(
        //   "eidCorrectedAdditionalSectionsRight",
        //   eidCorrectedAdditionalSectionsRight
        // );

        fifthCardLeftSections.splice(
          o2.section.length - 2,
          0,
          ...eidCorrectedAdditionalSectionsLeft
        );
        fifthCardRightSections.splice(
          fifthCardRightSections.length,
          0,
          ...eidCorrectedAdditionalSectionsRight
        );

        //card of date

        let eidCorrectedDateSectionsLeft = JSON.parse(
          JSON.stringify(cardOfDate.left)
        );
        eidCorrectedDateSectionsLeft.forEach((element: any, index: any) => {
          element.eid = `c13s${dateCardLeftSections.length + index + 1}`;
        });

        let eidCorrectedDateSectionsRight = JSON.parse(
          JSON.stringify(cardOfDate.right)
        );
        eidCorrectedDateSectionsRight.forEach((element: any, index: any) => {
          element.dependent_values[0].depend_upon[0] = `c13s${dateCardLeftSections.length + index + 1
            }`;

          let display =
            element.dependent_values[0].display.slice(
              0,
              element.dependent_values[0].display.indexOf("~") + 2
            ) +
            element.dependent_values[0].depend_upon[0] +
            element.dependent_values[0].display.slice(
              element.dependent_values[0].display.indexOf("]")
            );
          element.dependent_values[0].display = display;
        });

        // console.log(
        //   "eidCorrectedAdditionalSectionsRightdateeeeeee",
        //   eidCorrectedDateSectionsLeft
        // );
        // console.log(
        //   "eidCorrectedAdditionalSectionsRightdateeeeeeee",
        //   eidCorrectedDateSectionsRight
        // );

        dateCardLeftSections.splice(
          dateCardLeftSections.length,
          0,
          ...eidCorrectedDateSectionsLeft
        );
        dateCardRightSections.splice(
          dateCardRightSections.length,
          0,
          ...eidCorrectedDateSectionsRight
        );
      }

      // end ja

      //******************************Nein Delete fields*********************************** */
      if (o === "Nein") {
        let av = desiredCard.section;

        if (av.length > 5) {
          //Left Side

          //1
          av.splice(av.length - 5, 3);

          //5
          fifthCardLeftSections.splice(fifthCardLeftSections.length - 3, 3);

          //13
          dateCardLeftSections.splice(dateCardLeftSections.length - 2, 2);

          //Right Side

          //1
          desiredRightSection.splice(desiredRightSection.length - 3, 3);

          //5
          fifthCardRightSections.splice(fifthCardRightSections.length - 4, 4);

          //13
          dateCardRightSections.splice(dateCardRightSections.length - 2, 2);
        }
      }
      //****************************** End Nein Delete fields*********************************** */

      // if (o === "Nein"){

      //   console.log("neinnnnnnnnnnnnnnnnnn",right[0].section.filter((d:any)=>d.dependent_values[0].depend_upon[0]=='end')[0]
      //   )
      //   right[0].section.filter((d:any)=>d.dependent_values[0].depend_upon[0]=='end')[0].displayed=false

      // }
    }

    if (o2.id == 7) {
      if (o === "Ja") {
        right[0].section.filter(
          (d: any) => d.dependent_values[0].depend_upon[0] == "c7s2"
        )[0].displayed = true;



        currentSection.fields.filter((fd:any)=>fd.value==="Ja")[0].checked=true
        currentSection.fields.filter((fd:any)=>fd.value==="Nein")[0].checked=false


      } else {
        right[0].section.filter(
          (d: any) => d.dependent_values[0].depend_upon[0] == "c7s2"
        )[0].displayed = false;
      }

      if (o === "Nein") {
        right[0].section[2].displayed = true;
        // console.log("right......",right[0].section[2].displayed)


        currentSection.fields.filter((fd:any)=>fd.value==="Nein")[0].checked=true
        currentSection.fields.filter((fd:any)=>fd.value==="Ja")[0].checked=false

      } else {
        right[0].section[2].displayed = false;
        // console.log("right......",right[0].section[2].displayed)
      }
    }

    if (o1.eid == "c6s3") {
      if (o === "Ja") {
        right[0].section.filter(
          (d: any) => d.dependent_values[0].depend_upon[0] == "c6s4"
        )[0].displayed = true;



        currentSection.fields.filter((fd:any)=>fd.value==="Ja")[0].checked=true
        currentSection.fields.filter((fd:any)=>fd.value==="Nein")[0].checked=false

      }
      if (o === "Nein") {
        right[0].section.filter(
          (d: any) => d.dependent_values[0].depend_upon[0] == "c6s4"
        )[0].displayed = false;


        currentSection.fields.filter((fd:any)=>fd.value==="Nein")[0].checked=true
        currentSection.fields.filter((fd:any)=>fd.value==="Ja")[0].checked=false


      }
    }

    /**************************end************************************ */

    /******************generalized method of creating fields******************* */

    if (o1.newFieldsCreator === true) {
      if (o === "Ja") {
        currentSection.createFields = true;




        right[0].section.filter(
          (d: any) => d.dependent_values[0].depend_upon[0] == `${o1.eid}d1`
        )[0].displayed= true;

        if (right[0].section.filter((d: any) => d.dependent_values[0].depend_upon[0] == `${o1.eid}d2`)[0]) {
        
        right[0].section.filter(
        (d: any) => d.dependent_values[0].depend_upon[0] == `${o1.eid}d2`
        )[0].displayed= true;

        }




        currentSection.fields.filter((fd:any)=>fd.value==="Ja")[0].checked=true
        currentSection.fields.filter((fd:any)=>fd.value==="Nein")[0].checked=false
      } else {
        currentSection.createFields = false;



        right[0].section.filter(
          (d: any) => d.dependent_values[0].depend_upon[0] == `${o1.eid}d1`
        )[0].displayed= false;

        if (right[0].section.filter((d: any) => d.dependent_values[0].depend_upon[0] == `${o1.eid}d2`)[0]) {
        
        right[0].section.filter(
        (d: any) => d.dependent_values[0].depend_upon[0] == `${o1.eid}d2`
        )[0].displayed= false;

        }

        currentSection.fields.filter((fd:any)=>fd.value==="Nein")[0].checked=true
        currentSection.fields.filter((fd:any)=>fd.value==="Ja")[0].checked=false
      }
    }

    // dependent field for 4th card

    if (o2.id == 4) {
      if (o === "Nein") {
        currentSection.createFields = true;
        right[0].section[2].displayed = true;
      } else {
        currentSection.createFields = false;
        right[0].section[2].displayed = false;
      }
    }

    /***********************end of gen....************************ */

    /***********************end of gen....************************ */
    data.workspace
      .filter((g: any) => g.name === workspace.name)[0]
      .contracts.filter(
        (d: any) => d.contract_title === contract[0].contract_title
      )[0] = cc;
    savefile(data);
  };

  const handleExpDate = (event: any) => {
    d1.eye = true;
    d1.section.displayed = true;
    d1.section[0].displayed = true;
    d1.section[0].expdate = event.target.value;

    localStorage.setItem("Data", JSON.stringify(data1));
  };
  const handleEye = (content: any) => {
    if (content.eye === false) {
      handleVisibility(content);
    }
  };

  let opacity =
    Total /
    (content.section.length +
      content.section
        .map((a: any) => (a.createFields ? a.dependentFields.length : 0))
        .reduce((partialSum: any, a: any) => partialSum + a, 0));

  return (
    <div style={{ display: "flex", marginTop: "24px" }}>
      <Accordion
        id="card"
        // style={{ margin: css }}
        style={{
          boxShadow:
            shadow == true
              ? "0px 2px 4px 0px rgba(0, 0, 0, 0.15)"
              : "0px 0px 0px 0px #fff",
          border: shadow == true ? "none" : " 0.5px solid #71c480",
        }}
        expanded={expanded === "panel1"}
        onChange={handleAccordion("panel1")}
      >
        <ContextMenuTrigger id="contextmenuRename">
          <AccordionSummary
            style={{
              backgroundColor: `rgb(113, 196, 128,${opacity})`,
              borderRadius:
                expanded === "panel1" ? "16px 16px 0px 0px" : "16px",
              // height:"5.38px",
              // height:  expanded === "panel1" ? "5.55vh" : "5.55vh",
              // width:  expanded === "panel1" ? "38.75vw" : "38.75vw",
            }}
            onMouseOver={() => handleUpdateRenameID(content.id)}
            onClick={async () => {
              // console.log("before",content)

              const done = await handleCardExpansion(content);

              scrollFunc(name, done);
              scrollFuncRight(name);

              // console.log("after",content)
            }}
            // expandIcon={<ExpandMoreIcon />}
            expandIcon={
              expanded === "panel1" ? (
                <ExpandMoreIcon id="icon1" className="rotate1" />
              ) : (
                <ExpandMoreIcon id="icon2" className="rotateIcon" />
              )
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="badge">
              {Total}/
              {content.section.length +
                content.section
                  .map((a: any) =>
                    a.createFields ? a.dependentFields.length : 0
                  )
                  .reduce((partialSum: any, a: any) => partialSum + a, 0)}{" "}
            </div>
            {/* {content.completed_fields > 0 && (
              <div className="badge">
                {Total}/{content.total_fields}
              </div>
            )} */}
            <div id="heading1">
              {isadmin && !content.name_edit && (
                <Typography align="right" id="nameTypo" variant="h6">
                  {name}
                </Typography>
              )}
              {!isadmin && (
                <Typography align="right" id="nameTypo1" variant="h6">
                  {name}
                </Typography>
              )}
              {content.name_edit && isadmin && (
                <TextField
                  autoFocus
                  defaultValue={name}
                  id="standard-basic"
                  onKeyDown={handleSave}
                  label={""}
                  variant="standard"
                  size="small"
                />
              )}
            </div>
          </AccordionSummary>

          {/* Right Click Menu For Renaming  */}
          {isadmin && (
            <ContextMenu id="contextmenuRename">
              <MenuItem onClick={() => handleCardRename(data.renameID)}>
                0<span>Rename</span>
              </MenuItem>
            </ContextMenu>
          )}
        </ContextMenuTrigger>
        <AccordionDetails>
          <div style={{ padding: "0px 24px" }}>
            {content.section.map((a: any) => {
              const sectionEid = a.eid;
              return (
                <div>
                  {a.hrTag === true && <hr className="hrtag" />}
                  {a.hrTag === true && (
                    <div style={{textAlign:"end", marginBottom:"-25px"}}>
                      <DeleteOutlineIcon  style={{
                        color: "#71c480",
                        background: "white",
                        // marginLeft: "8px",
                        marginRight:"auto",
                        marginTop: "4px",
                        cursor:"pointer",
                      }}
                      onClick={() => {
                        handleRadioClick("Nein", a, content);
                      }}></DeleteOutlineIcon>
                    {/* <i
                      className="fas fa-trash"
                      style={{
                        color: "#71c480",
                        background: "white",
                        // marginLeft: "8px",
                        marginRight:"auto",
                        marginTop: "4px",
                      }}
                      onClick={() => {
                        handleRadioClick("Nein", a, content);
                      }}
                    ></i> */}
                    </div>
                  )}
                  <Typography id="statement" variant="subtitle1">
                    {a.mainStatement}
                  </Typography>

                  <Typography id="statement" variant="subtitle1">
                    {a.statement}
                  </Typography>

                  <div>
                    <ContextMenuTrigger id="contextmenuTF">
                      {a.type === "textfield" &&
                        a.fields.map((b: any) => (
                          <input
                            className="form-control"
                            style={{ marginTop: "20px" }}
                            onClick={async () => {
                              // console.log("afterafter",content)

                              await section(content, a, false);
                            }}
                            defaultValue={section(content, a, true)}
                            value={a.value}

                            placeholder={b.line}
                            id={b.eid}
                            type={a.type}
                            onChange={(e) => {
                              handleTextInput(e, a.eid);

                              if (e.target.value.length > 0) {
                                const cur = cards.filter(
                                  (lc: any) => lc.eid === sectionEid
                                )[0];

                                cur.count = 1;
                                localStorage.setItem(
                                  "Data",
                                  JSON.stringify(data1)
                                );
                              }
                              if (e.target.value.length == 0) {
                                const cur = cards.filter(
                                  (lc: any) => lc.eid === sectionEid
                                )[0];

                                cur.count = 0;
                                localStorage.setItem(
                                  "Data",
                                  JSON.stringify(data1)
                                );
                              }
                            }}
                          />
                        ))}

                      <ContextMenu id="contextmenuTF">
                        <MenuItem>
                          <span>
                            <i className="fas fa-info-circle"></i> TEXT FIELD
                          </span>
                        </MenuItem>
                      </ContextMenu>
                    </ContextMenuTrigger>
                    <ContextMenuTrigger id="contextmenuTF">
                      {a.type === "number" &&
                        a.fields.map((b: any) => (
                          <input
                            className="form-control"
                            style={{ marginTop: "20px" }}
                            onClick={async () => {
                              // console.log("afterafter",content)

                              await section(content, a, false);
                            }}
                            defaultValue={section(content, a, true)}
                            value={a.value}

                            placeholder={b.line}
                            id={b.eid}
                            type={a.type}
                            // onKeyPress='return event.charCode >= 48 && event.charCode <= 57'
                            onChange={(e) => {
                              handleTextInput(e, a.eid);

                              if (e.target.value.length > 0) {
                                const cur = cards.filter(
                                  (lc: any) => lc.eid === sectionEid
                                )[0];

                                cur.count = 1;
                                localStorage.setItem(
                                  "Data",
                                  JSON.stringify(data1)
                                );
                              }
                              if (e.target.value.length == 0) {
                                const cur = cards.filter(
                                  (lc: any) => lc.eid === sectionEid
                                )[0];

                                cur.count = 0;
                                localStorage.setItem(
                                  "Data",
                                  JSON.stringify(data1)
                                );
                              }
                            }}
                          />
                        ))}

                      <ContextMenu id="contextmenuTF">
                        <MenuItem>
                          <span>
                            <i className="fas fa-info-circle"></i> TEXT FIELD
                          </span>
                        </MenuItem>
                      </ContextMenu>
                    </ContextMenuTrigger>

                    <ContextMenuTrigger id="contextmenuDS">
                      {a.type === "dropselect" && (
                        <>
                          <hr  className="hrtag" />
                          <div style={{ display: "flex" }}>
                            {/* <div style={{ border: "1px solid ", width: "25px" }}>
                            <span className="ml-1">
                              {Math.trunc(content.section.length / 3)}{" "}
                            </span>
                          </div> */}
                            <div
                              className="plushover"
                              style={{
                                color: "#71c480",
                                marginLeft: "8px",
                                marginTop: "4px",
                                cursor: "pointer",
                                fontFamily: "Open Sans",
                              }}
                              onClick={() => {
                                handleRadioClick("Ja", a, content);
                              }}
                            >
                              WEITERE GESELLSCHAFTER HINZUFUGEN
                            </div>
                            <i
                              className="fas fa-plus-circle plusicon"
                              style={{
                                color: "#71c480",
                                background: "white",
                                marginLeft: "auto",
                                marginTop: "7px",
                                // marginRight:"auto"
                              }}
                              onClick={() => {
                                handleRadioClick("Ja", a, content);
                              }}
                            ></i>

                            {/* ************************remove the fields of first card  *************************/}

                            {/* <i
                            className="fas fa-minus"
                            style={{
                              color: "black",
                              background: "white",
                              marginLeft: "8px",
                              marginTop: "4px",
                            }}
                            onClick={() => {
                              handleRadioClick("Nein", a, content);
                            }}
                          ></i> */}

                            {/* ************End ------   remove the fields of first card  ***************************/}
                          </div>
                        </>
                      )}
                      <ContextMenu id="contextmenuDS">
                        <MenuItem>
                          <span>
                            <i className="fas fa-info-circle"></i> DROP SELECT
                          </span>
                        </MenuItem>
                      </ContextMenu>
                    </ContextMenuTrigger>
                    <ContextMenuTrigger id="contextmenuRB">
                      {a.type === "radio" &&
                        a.fields.map((option: any) => (
                          <div>
                            <Typography>
                              <label
                                className="rad"
                                style={{
                                  marginBottom: "0px",
                                  display: "-webkit-inline-box",
                                }}
                              >
                                <input
                                  onClick={() => {
                                    section(content, a, false);

                                    const cur = cards.filter(
                                      (lc: any) => lc.eid === a.eid
                                    )[0];
                                    cur.count = 1;
                                    localStorage.setItem(
                                      "Data",
                                      JSON.stringify(data1)
                                    );
                                  }}
                                  defaultValue={section(content, a, true)}
                                  onChange={() => {
                                    handleRadioClick(option.line, a, content);
                                  }}
                                  checked={option.checked}

                                  type={a.type}
                                  name={a.eid}
                                />{" "}
                                <i></i>
                                <Typography
                                  style={{
                                    marginLeft: "14px",
                                    verticalAlign: "middle",
                                    fontFamily: "Open Sans",
                                  }}
                                  variant="subtitle1"
                                >
                                  {" "}
                                  {" " + `${option.line}`}
                                </Typography>
                              </label>

                              <br />
                            </Typography>
                          </div>
                        ))}
                      <br />

                      <ContextMenu id="contextmenuRB">
                        <MenuItem>
                          <span>
                            <i className="fas fa-info-circle"></i> RADIO SELECT
                          </span>
                        </MenuItem>
                      </ContextMenu>
                    </ContextMenuTrigger>

                    <ContextMenuTrigger id="contextmenuTF">
                      <>
                        {/* {a.dependentFields && <>displaying<input></input></>} */}
                        {a.dependentFields && a.createFields === true && (
                          <>
                            <Typography id="statement" variant="subtitle1">
                              {a.dependentFields.statement}
                            </Typography>
                          </>
                        )}
                        {/* <hr/> */}
                        {a.dependentFields &&
                          a.createFields === true &&
                          a.dependentFields.map((df: any) => (
                            <div>
                              {console.log("df....", df)}
                              <Typography id="statement" variant="subtitle1">
                                {/* {console.log("hr tag hereeee",df.statement)} */}
                                {df.statement}
                              </Typography>
                              {df.fields.map((f: any) => (
                                <input
                                  className="form-control mt-1"
                                  style={{ marginBottom: "24px" }}
                                  onClick={async () =>
                                    await section(content, df, false)
                                  }
                                  defaultValue={section(content, df, true)}
                                  value={df.value}

                                  placeholder={f.line}
                                  id={f.eid}
                                  type={df.type}
                                  onChange={(e) => {
                                    handleTextInputforDependentFields(
                                      e,
                                      df.eid
                                    );

                                    if (e.target.value.length > 0) {
                                      const cur = cards.filter(
                                        (lc: any) => lc.eid === sectionEid
                                      )[0];

                                      cur.dependentFields.filter(
                                        (ff: any) => ff.eid === df.eid
                                      )[0].count = 1;
                                      localStorage.setItem(
                                        "Data",
                                        JSON.stringify(data1)
                                      );
                                    }
                                    if (e.target.value.length == 0) {
                                      const cur = cards.filter(
                                        (lc: any) => lc.eid === sectionEid
                                      )[0];

                                      cur.dependentFields.filter(
                                        (ff: any) => ff.eid === df.eid
                                      )[0].count = 0;
                                      localStorage.setItem(
                                        "Data",
                                        JSON.stringify(data1)
                                      );
                                    }
                                  }}
                                />
                              ))}
                            </div>
                          ))}
                        {/* <hr/> */}
                      </>
                      <ContextMenu id="contextmenuTF">
                        <MenuItem>
                          <span>
                            <i className="fas fa-info-circle"></i> TEXT FIELD
                          </span>
                        </MenuItem>
                      </ContextMenu>
                    </ContextMenuTrigger>

                    <ContextMenuTrigger id="contextmenuTF">
                      {a.type === "date" &&
                        a.fields.map((b: any) => (
                          <input
                            className="form-control"
                            style={{ marginBottom: "24px" }}
                            onClick={() => {
                              // console.log("afterafter",content)

                              section(content, a, false);
                            }}
                            // defaultValue={section(content, a, true)}
                            placeholder={b.line}
                            id={b.eid}
                            type={a.type}
                            // onChange={handleDate}
                            onChange={(e) => {
                              handleDate(e, a.eid);

                              if (e.target.value.length > 0) {
                                const cur = cards.filter(
                                  (lc: any) => lc.eid === sectionEid
                                )[0];

                                cur.count = 1;
                                localStorage.setItem(
                                  "Data",
                                  JSON.stringify(data1)
                                );
                              }
                              if (e.target.value.length == 0) {
                                const cur = cards.filter(
                                  (lc: any) => lc.eid === sectionEid
                                )[0];

                                cur.count = 0;
                                localStorage.setItem(
                                  "Data",
                                  JSON.stringify(data1)
                                );
                              }
                            }}
                          />
                        ))}

                      <ContextMenu id="contextmenuTF">
                        <MenuItem>
                          <span>
                            <i className="fas fa-info-circle"></i> Date
                          </span>
                        </MenuItem>
                      </ContextMenu>
                    </ContextMenuTrigger>
                  </div>
                </div>
              );
            })}
          </div>
        </AccordionDetails>
      </Accordion>

      {/* eye icon */}

      <div
        id="eyeIcon"
        className="btn"
        style={{
          position: "sticky",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        {content.eye === false && (
          <i
            onClick={() => handleVisibility(content)}
            className={"mt-3 fas fa-eye-slash"}
            style={{
              color: "grey",
            }}
          ></i>
        )}
        {content.eye === true && (
          <i
            style={{
              color: "#71C480",
              width: "20px",
              height: "16px",
            }}
            onClick={() => handleVisibility(content)}
            className={"mt-3 fas fa-eye"}
          ></i>
        )}
      </div>
    </div>
  );
};
export default Card;
