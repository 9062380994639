import React from "react";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import * as quillToWord from "quill-to-word";

import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import { jsPDF } from "jspdf";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import logo from "./CC_Logo.png";
import logo1 from "../imgs/logo.png";
import login from "../imgs/loginimg.png";
import ClipboardJS from "clipboard";

import { Container, Row, Col } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";

import WebViewer from "@pdftron/pdfjs-express-viewer";

import "./documentPreview.css";

const axios = require("axios").default;

let clipboard = new ClipboardJS(".button");
clipboard.on("success", (event: Clipboard.Event) => {
  event.clearSelection();
});

interface Props {
  admin: any;
  cname: any;
  workspace: any;
  cid: any;
}

const useStyles = makeStyles({
  input1: {
    width: "90%",
  },
  input2: {
    height: 200,
    fontSize: "3em",
  },
});

export default function DocumentPreview({
  admin,
  cname,
  workspace,
  cid,
}: Props) {
  const classes = useStyles();

  let datas = JSON.parse(localStorage.getItem("Data") || "{}");
  let Group = datas.workspace.filter((g: any) => g.id === workspace.id);
  let array = Group[0].contracts.filter((d: any) => d.id === cid);
  let leftArr = array[0].document.leftSide.card;
  let rightArr = array[0].document.rightSide.card;
  const viewer = useRef(null);

  function base64ToBlob(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], { type: "application/pdf" });
  }

// if using a class, equivalent of componentDidMount
useEffect(() => {
  var doc = new jsPDF({
    orientation: "p",
    unit: "pt",
    format: "a4",
  });
  // doc.fromHTML($())

  var pdfobj =
    "<div style='font-size:12px; font-family:sans-serif; width:550px; height:700px; letter-spacing:0.1px'>" +
    // "<header>" + "<img src=" + `${logo}` + "  style='width:50px; height:70px; padding-bottom:3.75%;' /> "+ "</header>" +
    document.getElementById("AAA")?.innerHTML +
    "</div>";
    //margin: [left, top, right ,bottom]
  doc.html(pdfobj, {
    margin: [50, 60, 50, 40],
    callback: function (doc) {
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.width
          ? pageSize.width
          : pageSize.getWidth();
        const pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();

        function getBase64Image(img: any) {
          var canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          var dataURL = canvas.toDataURL("image/png");
          return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
        }

        var base64 = getBase64Image(document.getElementById("printlogo"));
        // console.log("base64.......",base64);
        doc.addImage(base64, "PNG", 10, 0, 47, 47);

        // const header = "Clever Contracts 2.0";
        const footer = `Page ${i} of ${pageCount}`;

        // Header
        // doc.text(header, 40, 15, { baseline: "top" });

        //Footer
        doc.text(
          footer,
          pageWidth / 2 - doc.getTextWidth(footer) / 2,
          pageHeight - 15,
          {
            baseline: "bottom-right",
          }
        );
      }
      var blobPDF =doc.output('blob'); //new Blob([doc.output()], { type: "application/pdf" });



  //     var embb = `<Document  file={{
  //       data:${blobPDF}
  //     }} onLoadSuccess={onDocumentLoadSuccess} onLoadError={(error) => console.log("Inside Error", error)}>
  //       <Page height="600" pageNumber={pageNumber} />
  //     </Document>
  //     <p> Page {pageNumber} of {numPages}</p>
  //     { pageNumber > 1 && 
  //     <button onClick={changePageBack}>Previous Page</button>
  //     }
  //     {
  //       pageNumber < numPages &&
  //       <button onClick={changePageNext}>Next Page</button>
  //     }
  //   </header>`;

  // var parent = document.getElementById("pvPrint");
  
  // if(parent)
  // {
  //   parent.innerHTML = embb;
  //     }
    

      WebViewer(
        {
          path: "/webviewer/lib",
          licenseKey: "gJnKi5PSlroNTGglhBlh",
          disabledElements: [
            'header',
            
          ]
        },
        viewer.current
      ).then((instance) => {
        // now you can access APIs through the WebViewer instance
        const { Core, UI } = instance;

        instance.UI.setHeaderItems((header) => {
          const items = header.getItems().slice(9, -3);
          header.update(items);
        });
        instance.disableFeatures([instance.Feature.TextSelection]);
        instance.setLayoutMode(instance.LayoutMode.Single);
        instance.UI.disableElements(['toolsHeader']);
        const { documentViewer } = instance.Core;
        documentViewer.setWatermark({
          // Draw diagonal watermark in middle of the document
          diagonal: {
            fontSize: 25, // or even smaller size
            fontFamily: 'sans-serif',
            color: 'red',
            opacity: 50, // from 0 to 100
            text: ''
          },
    
          // Draw header watermark
          header: {
            fontSize: 10,
            fontFamily: 'sans-serif',
            color: 'red',
            opacity: 70,
            left: '',
            center: '',
            right: ''
          }
        });

        instance.UI.loadDocument(blobPDF, { filename: "myfile.pdf" });

        // adding an event listener for when a document is loaded
        Core.documentViewer.addEventListener("documentLoaded", () => {
          console.log("document loaded");
        });

        // adding an event listener for when the page number has changed
        Core.documentViewer.addEventListener(
          "pageNumberUpdated",
          (pageNumber) => {
            console.log(`Page number is: ${pageNumber}`);
          }
        );

        // adds a button to the header that on click sets the page to the next page
        UI.setHeaderItems((header) => {
          header.push({
            type: "actionButton",
            img: "https://icons.getbootstrap.com/assets/icons/caret-right-fill.svg",
            onClick: () => {
              const currentPage = Core.documentViewer.getCurrentPage();
              const totalPages = Core.documentViewer.getPageCount();
              const atLastPage = currentPage === totalPages;

              if (atLastPage) {
                Core.documentViewer.setCurrentPage(1);
              } else {
                Core.documentViewer.setCurrentPage(currentPage + 1);
              }
            },
          });
        });
      });
    
    
    },
  });
}, []);

  async function exportFunctionPDF() {
    var doc = new jsPDF({
      orientation: "p",
      unit: "pt",
      format: "a4",
    });
    // doc.fromHTML($())

    var pdfobj =
      "<div style='font-size:12px; font-family:sans-serif; width:596px; padding:5px 20px '>" +
      document.getElementById("AAA")?.innerHTML +
      "</div>";
    doc.html(pdfobj, {
      callback: function (doc) {
        doc.save("CC_2.0.pdf");
        // doc.output('dataurlnewwindow', {});
      },
    });
  }

  // PDF Export Function
  async function exportFunctionPDFMSK() {
    var doc = new jsPDF({
      orientation: "p",
      unit: "pt",
      format: "a4",
    });
    // doc.fromHTML($())
    //console.log(doc.getFontList());
    //doc.setFont('opensans-regular','normal');
    //doc.addFont('ArialMS', 'Arial', 'normal');
    // doc.setFont("Arial");

    var pdfobj =
      "<div style='font-size:12px; font-family:sans-serif; width:600px; height:700px; letter-spacing:0.1px'>" +
      // "<header>" + "<img src=" + `${logo}` + "  style='width:50px; height:70px; padding-bottom:3.75%;' /> "+ "</header>" +
      document.getElementById("AAA")?.innerHTML +
      "</div>";
    // console.log("PDFOBJ" , pdfobj);
    doc.html(pdfobj, {
      margin: [50, 60, 50, 40],
      callback: function (doc) {
        //doc.save("CC_2.0.pdf");
        // console.log(doc.getFontList());
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          const pageSize = doc.internal.pageSize;
          const pageWidth = pageSize.width
            ? pageSize.width
            : pageSize.getWidth();
          const pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();

          // function toDataURL(url, callback) {
          //   var xhr = new XMLHttpRequest();
          //   xhr.onload = function() {
          //     var reader = new FileReader();
          //     reader.onloadend = function() {
          //       callback(reader.result);
          //     }
          //     reader.readAsDataURL(xhr.response);
          //   };
          //   xhr.open('GET', url);
          //   xhr.responseType = 'blob';
          //   xhr.send();
          // }

          // for each page
          function getBase64Image(img: any) {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            var dataURL = canvas.toDataURL("image/png");
            return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
          }

          var base64 = getBase64Image(document.getElementById("printlogo"));
          // console.log("base64.......",base64);
          doc.addImage(base64, "PNG", 10, 0, 47, 47);

          // working perfectly on eachn page but need actual image base 64 code to put in imgdata.

          var imgData =
            "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAXwBfAAD/2wBDAAoHBwkHBgoJCAkLCwoMDxkQDw4ODx4WFxIZJCAmJSMgIyIoLTkwKCo2KyIjMkQyNjs9QEBAJjBGS0U+Sjk/QD3/2wBDAQsLCw8NDx0QEB09KSMpPT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT3/wgARCAAaABQDAREAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAABQYAAwQB/8QAGAEBAQEBAQAAAAAAAAAAAAAAAwEAAgT/2gAMAwEAAhADEAAAAXKbOK1c92KOHzuQcxaHNjdidpy5yl//xAAfEAACAQMFAQAAAAAAAAAAAAABAgADEhMEEBEhIjH/2gAIAQEAAQUC+QuVq6duEqnoephWKDia/FLjLjt//8QAHREAAgIBBQAAAAAAAAAAAAAAAAIBEQMSEyAiMf/aAAgBAwEBPwEhIZLj2DOttcCkNp7G8xZfH//EAB4RAAIDAAEFAAAAAAAAAAAAAAABAgMREiAhIjFR/9oACAECAQE/AR2ONmS9MolkcZZ8aHDl4b2FTEaEun//xAAhEAABAwMEAwAAAAAAAAAAAAABAAIRAxAxEjJBQiFhYv/aAAgBAQAGPwJQ7acIg8FQWFzfS0B0t+shcpkNqHx1KqahU29rZKybf//EAB0QAQADAQACAwAAAAAAAAAAAAEAESExQVFhgZH/2gAIAQEAAT8hUFrUE1U6+ZZvXITcrvpNdp4xEO+l1b7Gv7BQdYMALdXDkpwD7ipT+kOT/9oADAMBAAIAAwAAABBnmCSOz//EABsRAQACAwEBAAAAAAAAAAAAAAEAESExYSBx/9oACAEDAQE/EAXUQdz5KIsIMuNjTLWFPNMVwaOQoRsVXn//xAAcEQEAAgIDAQAAAAAAAAAAAAABABEhMSBhcVH/2gAIAQIBAT8QUMsIdQ9/JZNpSUTIImK3bZ5AbtfZa3cpbvj/AP/EABwQAQACAwEBAQAAAAAAAAAAAAEAESExQXFRwf/aAAgBAQABPxCsIatahd4Y+dDAb93fjD4HtO4qLlXU0ej2pdETsO11xEdV8cP2hExkSA2d3NHkA0Q0CIxSEyKmjyf/2Q==";

          //************************************************************************* */

          // doc.text(50, 40, "Header");

          // doc.html(document.body,40,100,function() {
          //     doc.save('web.pdf');
          // });

          // function  toDataURL(`${logo}`, function(dataUrl) {
          //     console.log('RESULT:', dataUrl)
          //   })

          // const header = function(headerData) {
          //   doc.setFontSize(20);
          //   doc.setTextColor(0, 190, 208);
          //   doc.setFontStyle('normal');
          //   if (base64I) {
          //     doc.addImage(base64I, 'JPEG', headerData.settings.margin.left, 15, 60, 10);
          //     doc.setFontSize(20);
          //   }
          //   doc.text('Header Title', headerData.settings.margin.left, 60);
          //   const currentdate = new Date();
          //   const datetime = currentdate.getDate() + '/' + (currentdate.getMonth() + 1) + '/' + currentdate.getFullYear();
          //   doc.text('Date: ' + datetime, headerData.settings.margin.left + 400, 60);
          //   doc.setFontSize(5);
          // };

          // const header = "Clever Contracts 2.0";
          const footer = `Page ${i} of ${pageCount}`;
          // Header
          // doc.text(header, 40, 15, { baseline: "top" });

          //Footer
          doc.text(
            footer,
            pageWidth / 2 - doc.getTextWidth(footer) / 2,
            pageHeight - 15,
            {
              baseline: "bottom-right",
            }
          );
        }

        doc.output("dataurlnewwindow", {});
      },
      image: {
        type: "png",
        quality: 100,
      },
      html2canvas: {
        scale: 0.9,
        allowTaint: true,
        letterRendering: true,
        svgRendering: true,
      },
    });
  }
  const [signedIn, setSignedIn] = useState(false);

  const [showSignIn, setShowSignIn] = useState(false);

  const [showSignUp, setShowSignUp] = useState(false);

  const handleClose = () => setShowSignIn(false);
  const handleCloseSignUp = () => setShowSignUp(false);
  let availabe = false;

  const handleShow = () => setShowSignUp(true);
  // if (localStorage.getItem("Data") === null) {
  //     localStorage.setItem("Data", JSON.stringify(data));
  //     let datas2 = JSON.parse(localStorage.getItem("Data") || "{}");
  // }

  const [values, setValues] = useState({
    fullname: "",
    email: "",
    password: "",
  });
  const [values1, setValues1] = useState({
    username: "",
    password: "",
  });
  const [formerrors, setFormErrors] = useState({});

  //this method handles the each form field changing and updates the relevant
  //state value for that input
  const handleChange = (event: any) => {
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };
  const handleChange1 = (event: any) => {
    setValues1((values1) => ({
      ...values1,
      [event.target.name]: event.target.value,
    }));
    // console.log("username222222222", event.target.name);
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();

    axios
      .get(
        "https://clever-contracts-api.herokuapp.com/api/getUserbyName/" +
          values.fullname,
        { timeout: 3000 }
      )
      // console.log("success!!!!!!!!!!!")
      .then(function (response: any) {
        console.log("gettting.....", response.data.length);
        if (response.data.length > 1) {
          alert("User Name already Exists!");
        } else {
          axios
            .get(
              "https://clever-contracts-api.herokuapp.com/api//getUserbyEmail/" +
                values.email
            )
            .then(function (response: any) {
              if (response.data.length > 1) {
                alert("Email already exists");
              } else {
                const { password, repeatPassword } = values;
                // perform all neccassary validations
                if (password !== repeatPassword) {
                  alert("Passwords don't match");
                } else {
                  // make API call

                  availabe = true;
                  axios
                    .post(
                      "https://clever-contracts-api.herokuapp.com/api/createuser",
                      {
                        orgid: "61e7ef54ddecf8450392385c",
                        fullname: values.fullname,
                        email: values.email,
                        password: values.password,
                      }
                    )
                    .then(function (response: any) {
                      if (response == 200) {
                        console.log("UserCreated");
                      }
                      console.log(response);
                      alert("Thank you for signing up!");
                      handleCloseSignUp();
                    })
                    .catch(function (error: any) {
                      console.log(error);
                    });
                }
              }
            })
            .catch(function (error: any) {
              // handle error
              console.log(error);
            });
        }
      })
      .catch(function (error: any) {
        // handle error
        console.log("hereeeeeeeeee");
        console.log(error);
      });
  };

  // Login APi

  const ShowLogin = () => {
    setShowSignUp(false);
    setShowSignIn(true);
  };

  const ShowRegister = () => {
    setShowSignIn(false);
    setShowSignUp(true);
  };

  const Export = () => {
    if (signedIn === true) {
      exportFunctionPDFMSK();
    } else {
      handleShow();
    }
  };
  const Print = () => {
    if (signedIn === true) {
      exportFunctionPDFMSK();
    } else {
      handleShow();
    }
  };

  const handleSubmit1 = (event: any) => {
    event.preventDefault();
    axios
      .get(
        "https://clever-contracts-api.herokuapp.com/api/getUserbyName/" +
          values1.username,
        // { timeout: 3000 }
      )
      // console.log("success!!!!!!!!!!!")
      .then(function (response: any) {
        console.log("gettting.....", response.data.length);
        if (response.data.length === 0) {
          alert("UserName Not Found");
        } else {
          let pass = response.data;
          // console.log("password.....", pass);
          // if (values1.username != "") {
          pass.forEach((e: any) => {
            if (values1.password.toString() === e.password.toString()) {
              // console.log("password", e.password);
              console.log("success.....");
              alert("Login SuccessFully!");
              localStorage.setItem("username", values1.username);
              localStorage.setItem("password", e.password);
              setSignedIn(true);
              handleClose();
              // break;
              return Promise.resolve();
            } else {
              alert("Wrong Password or UserName");
            }
          });
        }
      })
      .catch(function (error: any) {
        // handle error
        console.log("hereeeeeeeeee");
        console.log(error);
      });
  };

  function myFunction() {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  // const rmCheck = document.getElementById("checkbox"),
  // userInput = document.getElementById("user");

  // if (localStorage.checkbox && localStorage.checkbox !== "") {
  //   rmCheck.setAttribute("checked", "checked");
  //   console.log("mcehcek.....",rmCheck)
  //   values1.username = localStorage.username1;
  // } else {
  //   rmCheck.removeAttribute("checked");
  //   values1.username = "";
  // }

  // function lsRememberMe() {
  //   if (rmCheck.checked && values1.username !== "") {
  //     localStorage.username1 =values1.username;
  //     localStorage.checkbox = rmCheck.value;
  //   } else {
  //     localStorage.username1 = "";
  //     localStorage.checkbox = "";
  //   }
  // }
  async function exportFunctionDOC() {
    var quillDelta = JSON.parse(localStorage.getItem("Delta_value") || "{}");

    const quillToWordConfig = {
      exportAs: "blob",
    };

    const docAsBlob = await quillToWord.generateWord(
      quillDelta,
      quillToWordConfig
    );
    saveAs(docAsBlob, "CC_2.0.docx");
  }
  var RightDataPreview = (
    <div
      id="AAA"
      style={{
        overflow: "scroll",
        fontFamily: "Open Sans",
        height: "78vh",
        width: "90%",
        display: "none",
        //overflowY:"scroll",
      }}
      className=" container m-4 p-1 "
    >
      <img
        id="printlogo"
        style={{
          display: "contents",
          width: "50px",
          height: "70px",
          paddingBottom: "3.7%",
        }}
        src={logo1}
      />

      <div className="text-center">
        <p style={{ fontSize: "24px" }}>
          Gesellschaftsvertrages zur Gründung einer
        </p>
        <strong style={{ fontSize: "24px" }}>
          „Gesellschaft bürgerlichen Rechts“
        </strong>          <br /><br /><br />

      </div>

      {/* {console.log("rightArr........",rightArr)} */}
      {/* {console.log("rightarr isclicked.....", rightArr[0].isClicked)} */}
      {/* {console.log("displayed.......", rightArr[0].section[0].displayed)} */}
      {rightArr.map((a: any) => (
        <div   style={{
        
          marginBottom:"50px"
        }} >
          {a.eye &&
            a.section
              .filter((b: any) => b.displayed === true)

              .map((d: any) => (
                <>
                  {/* {console.log("a.islicked.......", a.isClicked)} */}
                  <span
                    id={a.name}
                    // style={d.style}
                    // style={{
                    //   borderLeft:
                    //     a.isClicked === true ? "3px solid #71c480" : "none",
                    //   opacity: a.isClicked === true ? "1" : "0.5",
                    // }}
                    dangerouslySetInnerHTML={{
                      __html: d.dependent_values[0].display
                        .replace(/~~.*]]/, "")
                        .replace("[[", ""),
                    }}
                  />
                </>
              ))}

          {a.eye &&
            a.section
              .filter(
                (b: any) => b.type === "signature" && b.displayed === true
              )
              .map((d: any) => (
                <div className="w-100">
                  <br />
                  <h2>
                    {" "}
                    <strong> {a.name}</strong>
                  </h2>
                  <p className="font-weight-bold">Glesafter</p>

                  <div>
                    {" "}
                    <p>
                      Name :{" "}
                      <span className="font-weight-normal"> {d.name} </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      {" "}
                      Date :{" "}
                      <span className="font-weight-normal">{d.date}</span>
                    </p>
                    <div>
                      Unterschrift________________________________________________
                    </div>
                  </div>
                  <br />
                  <p className="font-weight-bold">Glesafter</p>
                  <div>
                    {" "}
                    <p>
                      Name :{" "}
                      <span className="font-weight-normal"> {d.name1} </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      {" "}
                      Date :{" "}
                      <span className="font-weight-normal">{d.date1}</span>
                    </p>
                    <div>
                      Unterschrift________________________________________________
                    </div>
                  </div>
                </div>
              ))}
        </div>
      ))}
      <br />
    </div>
  );
  return (
    <div className="horScroll">
      <AppBar
        position="fixed"
        id="docpreview"
        style={{ background: "#f0f6f6" }}
      >
        <Toolbar variant="dense">
          <Typography
            variant="h5"
            color="#71c480"
            component="div"
            style={{ fontFamily: "Open Sans" }}
          >
            GbR Vertrag
          </Typography>

          {/* <Link to="/contractsOver">
            <i id="docpreview" className="far fa-file btn float-right">
              {" "}
              Document preview
            </i>
          </Link> */}

          {/* <IconButton
            style={{
              fontSize: "1.2rem",
              justifyContent: "flex-start",
              color: "black",
              id:"hovereffect",
              marginLeft: "auto",
            }}
            onClick={PrintDiv}
          > */}
          <div id="div1" style={{ display: "contents" }}>
            <div
              id="hovereffect"
              // className="mx-2"
              style={{
                height: "35px",
                width: "35px",
                marginLeft: "auto",
                marginRight: "10px",
                // paddingLeft:"-5px",
                // marginRight: "0.5rem!important",
                fontSize: "1.2rem",
                color: "black",
                textAlign: "center",
              }}
            >
              <Link to="/" style={{ float: "right", marginRight: "1vw" }}>
                <EditIcon
                  className="text-success button icolor"
                  style={{
                    opacity: "0.5",
                    height: "24px",
                    width: "24px",
                    // marginTop: "2vh",
                  }}
                  data-clipboard-action="copy"
                  data-clipboard-target="#AAA"
                />
              </Link>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Row>
        <Col sm={3} className="IMGLOGO">
          <img src={logo} style={{ width: "200px" }} />
          {/* <button className="btn btn-success" onClick={exportFunctionDOC}> Export as Doc</button> */}
        </Col>
        <Col
          sm={6}
          className="rightpreview"
          style={{ backgroundColor: "#f0f6f6" }}
        >
          <div
            id="div1"
            style={{
              display: "contents",
              // backgroundColor: "red",
              height: "500px",
            }}
          >
            <Row
              className="btnclass"
              style={{
                marginBottom: "7.4%",
                marginTop: "4.6%",
                // height: "4.6%",
                // marginLeft: "12%",
                // marginRight: "5%",
                // background:"red",
              }}
            >
              {/* <div style={{backgroundColor: "red"}}> */}
              {/* <div></div> */}
              <Col className="printdiv">
                <div
                  className="coloreffect1"
                  id="hovereffect"
                  // className="mx-2"
                  style={{
                    // border: "solid",
                    // borderWidth: "thin",
                    // borderRadius: "17px",
                    height: "100%",
                    // width: "35px",
                    marginRight: "10px",
                    marginLeft: "2px",
                    width: "100%",

                    // paddingLeft:"-5px",
                    fontSize: "1.2rem",
                    // color: "black",
                  }}
                  onClick={Print}
                  // onClick={exportFunctionPDFMSK}
                >
                  <PrintOutlinedIcon
                    className="icolor1"
                    style={{ opacity: "0.5", height: "55%", margin: "3%" }}
                  />
                  <span
                    className="coloreffect"
                    style={{
                      marginLeft: "10%",
                      fontSize: "70%",
                      fontWeight: "bold",
                      fontFamily: "Open Sans",
                    }}
                  >
                    PRINT
                  </span>
                </div>
              </Col>
              <Col className="copydiv">
                {/* when user is Signed in */}
                {signedIn === true && (
                  <div
                    // className="text-success icolor"
                    className="coloreffect1"
                    id="hovereffect"
                    // className="mx-2"
                    style={{
                      // border: "solid",
                      // borderWidth: "thin",
                      // borderRadius: "17px",
                      height: "100%",
                      // width: "35px",
                      // marginLeft: "auto",
                      marginRight: "10px",
                      width: "100%",

                      // paddingLeft:"-5px",
                      // marginRight: "0.5rem!important",
                      fontSize: "1.2rem",
                      color: "black",
                    }}
                  >
                    <ContentCopyIcon
                      className="icolor1"
                      style={{ opacity: "0.5", height: "55%", margin: "3%" }}
                      data-clipboard-action="copy"
                      data-clipboard-target="#AAA"
                    />
                    <span
                      className="coloreffect"
                      style={{
                        marginLeft: "10%",
                        fontSize: "70%",
                        fontWeight: "bold",
                        fontFamily: "Open Sans",
                      }}
                    >
                      {" "}
                      COPY
                    </span>
                  </div>
                )}

                {/* when user is not signed in */}
                {signedIn === false && (
                  <div
                    className="coloreffect1"
                    id="hovereffect"
                    // className="mx-2"
                    style={{
                      // border: "solid",
                      // borderWidth: "thin",
                      // borderRadius: "17px",
                      height: "100%",
                      // width: "35px",
                      // marginLeft: "auto",
                      marginRight: "10px",
                      width: "100%",

                      // paddingLeft:"-5px",
                      // marginRight: "0.5rem!important",
                      fontSize: "1.2rem",
                      color: "black",
                    }}
                    onClick={handleShow}
                  >
                    <ContentCopyIcon
                      className="icolor1"
                      style={{ opacity: "0.5", height: "55%", margin: "3%" }}
                      // data-clipboard-action="copy"
                      // data-clipboard-target="#AAA"
                    />
                    <span
                      className="coloreffect"
                      style={{
                        marginLeft: "10%",
                        fontSize: "70%",
                        fontWeight: "bold",
                        fontFamily: "Open Sans",
                      }}
                    >
                      {" "}
                      COPY
                    </span>
                  </div>
                )}
              </Col>
              <Col className="exportdiv">
                <div
                  className="coloreffect1"
                  id="hovereffect"
                  // className="mx-2"
                  style={{
                    // border: "solid",
                    // borderWidth: "thin",

                    // borderRadius: "17px",
                    height: "100%",
                    width: "100%",

                    // width: "35px",
                    // marginLeft: "auto",
                    // paddingLeft:"-5px",
                    // marginRight: "0.5rem!important",
                    fontSize: "1.2rem",

                    // color: "black",
                  }}
                  onClick={Export}
                >
                  <ExitToAppIcon
                    className="icolor1"
                    style={{ opacity: "0.5", height: "55%", margin: "3%" }}
                  />
                  <span
                    className="coloreffect"
                    style={{
                      marginLeft: "10%",
                      fontSize: "70%",
                      fontWeight: "bold",
                      fontFamily: "Open Sans",
                    }}
                  >
                    EXPORT
                  </span>
                </div>
              </Col>
              {/* </div> */}
            </Row>
            <div
              className="preview"
              style={{
                // backgroundColor: "white",
                // width:"30vw",
                // height: "78vh",
                marginLeft: "13%",
                marginRight: "7vw",
                marginBottom: "6vh",
              }}
            >
              {RightDataPreview}
              <div
                style={{
                  overflow: "scroll",
                  fontFamily: "Open Sans",
                  // height: "78vh",
                  // maxWidth:"100vw",
                  // minWidth:"37vw",
                  // width: "90%",
                }}
                className="pdfviewerclass"
                ref={viewer}
              ></div>
            </div>
          </div>
        </Col>

        {/* /******************************************* *sign In Modal */}
        <Modal
          show={showSignIn}
          onHide={handleClose}
          animation={false}
          dialogClassName="my-modal"
          centered
        >
          <Row style={{ margin: "7px" }}>
            <Col
              className="loginside"
              style={{
                backgroundColor: "#f0f6f6",
                // width: "450px",
                height: "85vh",
              }}
            >
              <Typography
                variant="h4"
                style={{
                  fontFamily: "Open Sans",
                  marginTop: "5.6%",
                  marginLeft: "4.2%",
                  color: "#71c480",
                }}
              >
                Clever Contracts
              </Typography>{" "}
              <Typography
                variant="subtitle1"
                style={{
                  fontFamily: "Open Sans",
                  marginLeft: "4.2%",
                }}
              >
                {" "}
                Go to{" "}
                <a
                  href="https://www.clevercontracts.de/"
                  style={{ color: "#71c480" }}
                >
                  clevercontracts.de
                </a>{" "}
                for more information.
              </Typography>
              <br />
              <img
                style={{
                  width: "80%",
                  marginTop: "18%",
                  marginBottom: "15%",
                  marginLeft: "4.2%",
                }}
                src={login}
              />
              <Typography
                variant="h4"
                style={{ marginLeft: "4.2%", color: "#71c480" }}
              >
                Vertrage. <br />
                Einfach. <br />
                Intelligent. <br />
              </Typography>
            </Col>
            <Col
            // style={{ width: "450px", height: "750px" }}
            >
              {/* <i className="fas fa-times closeicon"style={{marginLeft:"-20px"}} onClick={handleClose}></i> */}
              <Typography
                variant="h5"
                style={{ marginTop: "5.6%", marginLeft: "4.2%" }}
              >
                <i className="fas fa-times closeicon" onClick={handleClose}></i>{" "}
                Login to Clever Account
              </Typography>
              <br />
              <Typography
                variant="body1"
                style={{
                  fontFamily: "Open Sans",
                  marginBottom: "7.4%",
                  marginLeft: "4.2%",
                }}
              >
                {" "}
                Welcome Back! Login to enable the features like{" "}
                <strong style={{ color: "#71c480" }}>
                  {" "}
                  Print, Copy and Export
                </strong>{" "}
                the Document and more.
              </Typography>
              <Form
                onSubmit={handleSubmit1}
                style={{ marginTop: "7%", marginBottom: "5.6%" }}
              >
                {/* <Form.Group
                  style={{ fontFamily: "Open Sans", marginBottom: "2.8%" }}
                  controlId="formGroupEmail"
                > */}
                {/* <Form.Label>UserName</Form.Label> */}
                <TextField
                  // id="user"
                  id="outlined-basic"
                  type="text"
                  placeholder="User Name"
                  name="username"
                  label="User Name"
                  variant="outlined"
                  // value={values1.username}
                  onChange={handleChange1}
                  style={{
                    // width: "340px",
                    // height: "44px",
                    // border: "0.5px solid #71c480",
                    borderRadius: "3px",
                    marginLeft: "4.2%",
                    marginRight: "5.2%",
                    marginBottom: "2%",
                  }}
                  className={classes.input1}
                  required
                />
                {/* </Form.Group> */}
                {/* <Form.Group className="mb-3" controlId="formGroupPassword"> */}
                {/* <Form.Label> <i className="far fa-eye" style={{color:" #71c480"}} onClick={myFunction} /></Form.Label> */}
                <TextField
                  // id="myInput"
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  type="password"
                  placeholder="Password"
                  name="password"
                  // value={values1.password}
                  onChange={handleChange1}
                  style={{
                    // width: "340px",
                    // height: "44px",
                    // border: "0.5px solid #71c480",
                    borderRadius: "3px",
                    marginLeft: "4.2%",
                    marginRight: "5.2%",
                  }}
                  className={classes.input1}
                  required
                ></TextField>
                <span
                  style={{
                    float: "right",
                    marginRight: "7%",
                    fontSize: "13px",
                    color: "#71c480",
                  }}
                >
                  <a href="#" style={{ fontSize: "13px", color: "#71c480" }}>
                    Forgot password?
                  </a>
                </span>
                {/* </Form.Group> */}
                <div className="round">
                  <input
                    type="checkbox"
                    id="checkbox"
                    // value="lsRememberMe"
                    // onClick={lsRememberMe}
                  />
                  <label htmlFor="checkbox"></label>
                  <span style={{ marginLeft: "25px", fontWeight: "bold" }}>
                    Remember Me
                  </span>
                </div>

                <img
                  className="imgside"
                  style={{
                    width: "90%",
                    marginTop: "18%",
                    marginBottom: "15%",
                    marginLeft: "4.2%",
                  }}
                  src={login}
                />
                <Button
                  variant="outlined"
                  type="submit"
                  className="text-success button icolor"
                  style={{
                    marginTop: "11.1%",
                    marginLeft: "4.2%",
                    marginRight: "5%",
                    width: "90%",
                    backgroundColor: "#f0f6f6",
                    borderColor: "green",
                  }}
                >
                  Login
                </Button>
                <div>
                  <span
                    style={{
                      float: "left",
                      marginLeft: "7%",
                      fontSize: "13px",
                      color: "#71c480",
                    }}
                  >
                    <a style={{ fontSize: "13px", color: "#71c480" }}>
                      New User?
                    </a>
                  </span>

                  <span
                    style={{
                      float: "right",
                      marginRight: "9%",
                      fontSize: "13px",
                      color: "#71c480",
                    }}
                  >
                    <a
                      onClick={ShowRegister}
                      style={{
                        fontSize: "13px",
                        color: "#71c480",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Register Here
                    </a>
                  </span>
                </div>
              </Form>
            </Col>
          </Row>
          {/* </Modal.Body> */}
          {/* <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer> */}
        </Modal>

        {/* /* ****************************************SignUp modal */}

        <Modal
          show={showSignUp}
          onHide={handleCloseSignUp}
          dialogClassName="my-modal"
          animation={false}
          centered
          className="signupmodel"
        >
          {/* 
                    <Modal.Header closeButton>
                        <Modal.Title>Please signup first to get your file...</Modal.Title>
                    </Modal.Header> */}
          {/* <Modal.Body> */}
          <Row style={{ margin: "10px" }}>
            <Col
              className="loginside"
              style={{ backgroundColor: "#f0f6f6", height: "85vh" }}
            >
              <div
                style={{
                  marginLeft: "4.2%",
                  marginRight: "5%",
                  marginTop: "5.6%",
                }}
              >
                <Typography variant="h4" className="text-success heading">
                  Clever Contracts
                </Typography>
                <Typography variant="subtitle1">
                  Go to{" "}
                  <a
                    href="https://www.clevercontracts.de/"
                    style={{ color: "#71c480" }}
                  >
                    clevercontracts.de
                  </a>{" "}
                  for more information.
                </Typography>
                <br />
              </div>
              <img
                style={{
                  width: "80%",
                  marginTop: "18%",
                  marginBottom: "15%",
                  marginLeft: "4.2%",
                }}
                src={login}
              />
              <br />
              <div
                style={{
                  marginLeft: "4.2%",
                  marginRight: "5%",
                  marginTop: "5.6%",
                }}
              >
                <Typography variant="h4" className="text-success ">
                  Vertrage. <br />
                  Einfach. <br />
                  Intelligent. <br />{" "}
                </Typography>
              </div>
            </Col>
            <Col>
              <div
                style={{
                  marginLeft: "4.2%",
                  marginRight: "5%",
                }}
              >
                {/* <i className="fas fa-times closeicon" onClick={handleCloseSignUp}></i> */}
                <Typography variant="h5" style={{ marginTop: "5%" }}>
                  <i
                    className="fas fa-times closeicon"
                    onClick={handleCloseSignUp}
                  ></i>
                  Register Clever Account.
                </Typography>
                <Typography variant="subtitle1">
                  If you already have an account please{" "}
                  <a
                    onClick={ShowLogin}
                    style={{
                      color: "#71c480",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Log in
                  </a>{" "}
                  here.
                </Typography>
              </div>
              <Form
                onSubmit={handleSubmit}
                style={{ marginTop: "7%", marginBottom: "5.6%" }}
              >
                {/* <Form.Group className="mb-3" controlId="formGroupEmail">
                                    <Form.Label>UserName</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="User Name"
                                        name="fullname"
                                        value={values.fullname}
                                        onChange={handleChange}
                                        required
                                        style={{ marginLeft: "4.2%", marginRight: "5%", height: "5px" }}
                                    />
                                </Form.Group> */}
                <TextField
                  name="fullname"
                  value={values.fullname}
                  onChange={handleChange}
                  required
                  id="outlined-basic"
                  label="User Name"
                  variant="outlined"
                  style={{
                    marginLeft: "4.2%",
                    marginRight: "5%",
                    marginBottom: "2%",
                  }}
                  className={classes.input1}
                />

                <TextField
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  required
                  id="outlined-basic"
                  label="Email Address"
                  variant="outlined"
                  style={{
                    marginLeft: "4.2%",
                    marginRight: "5%",
                    marginBottom: "2%",
                  }}
                  className={classes.input1}
                />

                <TextField
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  required
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  style={{
                    marginLeft: "4.2%",
                    marginRight: "5%",
                  }}
                  className={classes.input1}
                />
                <div
                  style={{
                    marginLeft: "8%",
                    marginBottom: "2%",
                    marginRight: "8%",
                  }}
                >
                  <Typography variant="caption">
                    Password should contain atleast one uppercase letter.
                  </Typography>
                </div>

                <TextField
                  id="outlined-basic"
                  type="password"
                  name="repeatPassword"
                  value={values.repeatPassword}
                  onChange={handleChange}
                  label="Repeat Password"
                  variant="outlined"
                  required
                  style={{
                    marginLeft: "4.2%",
                    marginRight: "5%",
                  }}
                  className={classes.input1}
                />

                {/* <Form.Group className="mb-3" controlId="formGroupEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Email address"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formGroupPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        name="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group> */}

                <img
                  className="imgside"
                  style={{
                    width: "90%",
                    marginTop: "18%",
                    marginBottom: "15%",
                    marginLeft: "4.2%",
                  }}
                  src={login}
                />
                {/* <br /> */}
                <Button
                  variant="outlined"
                  type="submit"
                  className="text-success button icolor"
                  style={{
                    marginTop: "11.1%",
                    marginLeft: "4.2%",
                    marginRight: "5%",
                    width: "90%",
                    backgroundColor: "#f0f6f6",
                    borderColor: "green",
                  }}
                >
                  Register
                </Button>
                <br />
                <div
                  style={{
                    marginLeft: "8%",
                    marginRight: "8%",
                  }}
                >
                  <Typography variant="caption">
                    By creating an account, you agree to our Terms of Service
                    and Privacy & Cookie Statement.
                  </Typography>
                </div>
              </Form>
            </Col>
          </Row>
          {/* </Modal.Body> */}
          {/* <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer> */}
        </Modal>

        <Col sm={3} className="IMGLOGO">
          <div
            id="hovereffect1"
            // className="mx-2"
            style={{
              height: "35px",
              width: "35px",
              marginLeft: "auto",
              marginRight: "10px",
              // paddingLeft:"-5px",
              // marginRight: "0.5rem!important",
              fontSize: "1.2rem",
              color: "black",
              // textAlign: "center",
            }}
          >
            <Link to="/" style={{ float: "right", marginRight: "1vw" }}>
              <EditIcon
                className="text-success button icolor"
                style={{
                  opacity: "0.5",
                  height: "24px",
                  width: "24px",
                  // marginTop: "1vh",
                  marginRight: "-10px",
                }}
                data-clipboard-action="copy"
                data-clipboard-target="#AAA"
              />
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
}
