export const workspaceDemo = 

{
    "id": 47,
    "contractsId": 2,
    "name": "daniyaltest",
    "image": "",
    "contracts": [
        {
            "contract_title": "GbR Vertag",
            "document": {
                "leftSide": {
                    "linearprogressvalue": 0,
                    "card": [
                        {
                            "id": 1,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Vertragspartner",
                            "name_edit": false,
                            "total_fields": 5,
                            "completed_fields": 0,
                            "linearprogressvalue": 0,
                            "isactive": false,
                            "havesomevalue": false,
                            "isedited": false,
                            "import_counter": 0,
                            "signaturecard": false,
                            "section_id_length": 3,
                            "section": [
                                
                                {
                                    "eid": "s1",
                                    "statement": "Wer soll Vertragspartner werden?",
                                    "type": "textfield",
                                    "length": 1,
                                    "textfieldapproval": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "editcontent": false,
                                    "fields": [
                                        {
                                            "eid": "s1-ms1",
                                            "statement": "Name Gesellschafter",
                                            "line": "Name Gesellschafter",
                                            "value": "",
                                            "size": "col-12"
                                        }
                                    ]
                                },
                                {
                                    "eid": "s2",
                                    "statement": "Straße, Hausnummer",
                                    "type": "textfield",
                                    "length": 1,
                                    "textfieldapproval": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "editcontent": false,
                                    "fields": [
                                        {
                                            "eid": "s2-ms1",
                                            "line": "Straße, Hausnummer",
                                            "value": "",
                                            "size": "col-12"
                                        }
                                    ]
                                },
                                {
                                    "eid": "s3",
                                    "statement": "PLZ, Ort",
                                    "type": "textfield",
                                    "length": 1,
                                    "textfieldapproval": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "editcontent": false,
                                    "fields": [
                                        {
                                            "eid": "s3-ms1",
                                            "line": "PLZ, Ort",
                                            "value": "",
                                            "size": "col-12"
                                        }
                                    ]
                                },
                                {
                                    "tiptapedit": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "editcontent": false,
                                    "eid": "s4",
                                    "statement": "Sollen weitere Gesellschafter Teil des Vertrages werden?",
                                    "type": "radio",
                                    "length": 8,
                                    "value": null,
                                    "ishelp": true,
                                    "ishelpstatement": "Hiervon ist abhängig, wann der Vertragsschluss zustande kommt. Sind Ihre Angebote bereits bindend, kommt der Vertragsschluss mit der Bestellung durch den Kunden zustande. Wir empfehlen grundsätzlich die Angebote als nicht bindend einzuordnen, da Sie sich hiermit die Möglichkeit offenhalten, Bestellungen aus verschiedensten Gründen (z.B. Lieferengpass etc.) nicht zu bestätigen",
                                    "fields": [
                                        {
                                            "tiptapedit": false,
                                            "eid": "s4-ms1",
                                            "value": "Ja",
                                            "line": "Ja"
                                        },
                                        {
                                            "tiptapedit": false,
                                            "eid": "s4-ms2",
                                            "value": "Nein",
                                            "line": "Nein"
                                        }
                                    ],
                                    "textfieldapproval": true,
                                    "textfieldapprovalfields": [
                                        {
                                            "tiptapedit": false,
                                            "eid": "s4-ms3",
                                            "on_which_to_show": "Ja",
                                            "line": "Name Gesellschafter",
                                            "value": ""
                                        },
                                        {
                                            "tiptapedit": false,
                                            "eid": "s4-ms4",
                                            "on_which_to_show": "Ja",
                                            "line": "Straße, Hausnummer",
                                            "value": ""
                                        },
                                        {
                                            "tiptapedit": false,
                                            "eid": "s4-ms5",
                                            "on_which_to_show": "Ja",
                                            "line": "PLZ, Ort",
                                            "value": ""
                                        }
                                    ]
                                },
                                {
                                    "tiptapedit": false,
                                    "editcontent": false,
                                    "eid": "s5",
                                    "statement": "Anzahl der Gesellschafter",
                                    "type": "dropselect",
                                    "length": 2,
                                    "value": "",
                                    "choices": [
                                        "1",
                                        "2",
                                        "3",
                                        "4",
                                        "5",
                                        "6",
                                        "7",
                                        "8"
                                    ],
                                    "dropselect_display": true,
                                    "textfieldapproval": false,
                                    "fields_display": false,
                                    "ishelp": false,
                                    "dropselect": [
                                        {
                                            "tiptapedit": false,
                                            "on_which_to_show": "1",
                                            "eid": "s5-ms1",
                                            "statement": "Wer soll Vertragspartner des Kunden werden?",
                                            "type": "dropselect",
                                            "length": 2,
                                            "value": "",
                                            "choices": [
                                                "keine Angabe",
                                                "Frau",
                                                "Herr"
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "id": 2,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Name, Sitz und Zweck der Gesellschaft",
                            "name_edit": false,
                            "total_fields": 4,
                            "completed_fields": 0,
                            "linearprogressvalue": 0,
                            "isactive": false,
                            "havesomevalue": false,
                            "isedited": false,
                            "import_counter": 0,
                            "signaturecard": false,
                            "section_id_length": 8,
                            "section": [
                                {
                                    "tiptapedit": false,
                                    "textfieldapproval": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "editcontent": false,
                                    "ishelp": false,
                                    "eid": "s6",
                                    "statement": "Wie lautet die Bezeichnung Ihrer Gesellschaft? ",
                                    "type": "textfield",
                                    "length": 1,
                                    "fields": [
                                        {
                                            "eid": "s6-ms1",
                                            "line": "",
                                            "value": "",
                                            "size": "col-12"
                                        }
                                    ]
                                },
                                {
                                    "tiptapedit": false,
                                    "editcontent": false,
                                    "textfieldapproval": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "eid": "s7",
                                    "statement": "Was ist der Geschäftsbereich?",
                                    "type": "textfield",
                                    "length": 1,
                                    "fields": [
                                        {
                                            "eid": "s7-ms1",
                                            "line": "",
                                            "value": "",
                                            "size": "col-12"
                                        }
                                    ]
                                },
                                {
                                    "tiptapedit": false,
                                    "fields_display": true,
                                    "eid": "s5",
                                    "editcontent": false,
                                    "statement": "Sollen Filialen gegründet werden?",
                                    "type": "radio",
                                    "length": 2,
                                    "value": null,
                                    "ishelp": true,
                                    "ishelpstatement": "Sie sind nicht verpflichtet den Vertragstext zu speichern. Sofern Sie dies jedoch tun, muss der Kunde in den AGB hierüber informiert werden",
                                    "fields": [
                                        {
                                            "tiptapedit": false,
                                            "eid": "s5-ms1",
                                            "value": "Ja",
                                            "line": "Ja"
                                        },
                                        {
                                            "tiptapedit": false,
                                            "eid": "s5-ms2",
                                            "value": "Nein",
                                            "line": "Nein"
                                        }
                                    ]
                                },
                                {
                                    "textfieldapproval": false,
                                    "dropselect_display": false,
                                    "editcontent": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "eid": "s1",
                                    "statement": "Wo ist der Sitz der Gesellschaft?",
                                    "type": "textfield",
                                    "length": 1,
                                    "fields": [
                                        {
                                            "eid": "s1-ms1",
                                            "line": "",
                                            "value": "",
                                            "size": "col-12"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "id": 3,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Dauer der Gesellschaft",
                            "name_edit": false,
                            "total_fields": 1,
                            "completed_fields": 0,
                            "linearprogressvalue": 0,
                            "isactive": false,
                            "havesomevalue": false,
                            "isedited": false,
                            "import_counter": 0,
                            "signaturecard": false,
                            "section_id_length": 4,
                            "section": [
                                {
                                    "textfieldapproval": false,
                                    "dropselect_display": false,
                                    "editcontent": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "eid": "s1",
                                    "statement": "Wann soll die Gesellschaft ihren Betrieb aufnehmen bzw. wann werden die Gesellschaft die Geschäfte anfangen?",
                                    "type": "textfield",
                                    "length": 1,
                                    "fields": [
                                        {
                                            "eid": "s1-ms1",
                                            "line": "",
                                            "value": "",
                                            "size": "col-12"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "id": 4,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Geschäftsjahr",
                            "name_edit": false,
                            "total_fields": 1,
                            "completed_fields": 0,
                            "linearprogressvalue": 0,
                            "isactive": false,
                            "havesomevalue": false,
                            "isedited": false,
                            "import_counter": 0,
                            "signaturecard": false,
                            "section_id_length": 1,
                            "section": [
                                {
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "editcontent": false,
                                    "eid": "s1",
                                    "statement": "Das Geschäftsjahr entspricht dem Kalenderjahr.",
                                    "type": "radio",
                                    "length": 2,
                                    "value": null,
                                    "fields": [
                                        {
                                            "tiptapedit": false,
                                            "eid": "s1-ms1",
                                            "value": "Ja",
                                            "line": "Ja"
                                        },
                                        {
                                            "tiptapedit": false,
                                            "eid": "s1-ms2",
                                            "value": "Nein",
                                            "line": "Nein"
                                        }
                                    ],
                                    "textfieldapproval": false,
                                    "textfieldapprovalfields": []
                                }
                            ]
                        },
                        {
                            "id": 5,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Einlagen der Gesellschafter",
                            "name_edit": false,
                            "total_fields": 7,
                            "completed_fields": 0,
                            "linearprogressvalue": 0,
                            "isactive": false,
                            "havesomevalue": true,
                            "isedited": false,
                            "import_counter": 0,
                            "signaturecard": false,
                            "section_id_length": 1,
                            "section": [
                                {
                                    "tiptapedit": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "editcontent": false,
                                    "ishelp": false,
                                    "eid": "s4",
                                    "statement": "Bringt Gesellschafter Geld in bar in die Gesellschaft ein?",
                                    "type": "radio",
                                    "length": 2,
                                    "value": null,
                                    "fields": [
                                        {
                                            "tiptapedit": false,
                                            "eid": "s4-ms1",
                                            "value": "Ja",
                                            "line": "Ja"
                                        },
                                        {
                                            "tiptapedit": false,
                                            "eid": "s4-ms2",
                                            "value": "Nein",
                                            "line": "Nein"
                                        }
                                    ],
                                    "textfieldapproval": true,
                                    "textfieldapprovalfields": [
                                        {
                                            "statement": "Wie viel Geld in bar bringt der Gesellschafter in die Gesellschaft ein?",
                                            "tiptapedit": false,
                                            "eid": "s4-ms3",
                                            "on_which_to_show": "Ja",
                                            "line": "",
                                            "value": ""
                                        }
                                    ]
                                },
                                {
                                    "tiptapedit": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "editcontent": false,
                                    "ishelp": false,
                                    "eid": "s5",
                                    "statement": "Bringt Gesellschafter Geld in bar in die Gesellschaft ein?",
                                    "type": "radio",
                                    "length": 2,
                                    "value": null,
                                    "fields": [
                                        {
                                            "tiptapedit": false,
                                            "eid": "s5-ms1",
                                            "value": "Ja",
                                            "line": "Ja"
                                        },
                                        {
                                            "tiptapedit": false,
                                            "eid": "s5-ms2",
                                            "value": "Nein",
                                            "line": "Nein"
                                        }
                                    ],
                                    "textfieldapproval": true,
                                    "textfieldapprovalfields": [
                                        {
                                            "statement": "Welche materielle Werte werden in die Gesellschaft eingebracht?",
                                            "tiptapedit": false,
                                            "eid": "s5-ms4",
                                            "on_which_to_show": "Ja",
                                            "line": "",
                                            "value": ""
                                        },
                                        {
                                            "statement": "Wie hoch ist der finanzielle Wert der materiellen Werte?",
                                            "tiptapedit": false,
                                            "eid": "s5-ms5",
                                            "on_which_to_show": "Ja",
                                            "line": "",
                                            "value": ""
                                        }
                                    ]
                                },
                                {
                                    "tiptapedit": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "editcontent": false,
                                    "ishelp": false,
                                    "eid": "s6",
                                    "statement": "Bringt Gesellschafter Geld in bar in die Gesellschaft ein?",
                                    "type": "radio",
                                    "length": 2,
                                    "value": null,
                                    "fields": [
                                        {
                                            "tiptapedit": false,
                                            "eid": "s6-ms1",
                                            "value": "Ja",
                                            "line": "Ja"
                                        },
                                        {
                                            "tiptapedit": false,
                                            "eid": "s6-ms2",
                                            "value": "Nein",
                                            "line": "Nein"
                                        }
                                    ],
                                    "textfieldapproval": true,
                                    "textfieldapprovalfields": [
                                        {
                                            "statement": "Wie viel Geld in bar bringt der Gesellschafter in die Gesellschaft ein?",
                                            "tiptapedit": false,
                                            "eid": "s6-ms3",
                                            "on_which_to_show": "Ja",
                                            "line": "",
                                            "value": ""
                                        }
                                    ]
                                },
                                {
                                    "tiptapedit": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "editcontent": false,
                                    "ishelp": false,
                                    "eid": "s7",
                                    "statement": "Bringt Gesellschafter Geld in bar in die Gesellschaft ein?",
                                    "type": "radio",
                                    "length": 2,
                                    "value": null,
                                    "fields": [
                                        {
                                            "tiptapedit": false,
                                            "eid": "s7-ms1",
                                            "value": "Ja",
                                            "line": "Ja"
                                        },
                                        {
                                            "tiptapedit": false,
                                            "eid": "s7-ms2",
                                            "value": "Nein",
                                            "line": "Nein"
                                        }
                                    ],
                                    "textfieldapproval": true,
                                    "textfieldapprovalfields": [
                                        {
                                            "statement": "Welche materielle Werte werden in die Gesellschaft eingebracht?",
                                            "tiptapedit": false,
                                            "eid": "s7-ms4",
                                            "on_which_to_show": "Ja",
                                            "line": "",
                                            "value": ""
                                        },
                                        {
                                            "statement": "Wie hoch ist der finanzielle Wert der materiellen Werte?",
                                            "tiptapedit": false,
                                            "eid": "s7-ms5",
                                            "on_which_to_show": "Ja",
                                            "line": "",
                                            "value": ""
                                        }
                                    ]
                                },
                                {
                                    "textfieldapproval": false,
                                    "dropselect_display": false,
                                    "editcontent": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "eid": "s8",
                                    "statement": "In welcher Höhe ist Gesellschafter Output: Input Field  1 am Gesellschaftsvermögen am beteiligt?",
                                    "type": "textfield",
                                    "length": 1,
                                    "fields": [
                                        {
                                            "eid": "s8-ms1",
                                            "line": "",
                                            "value": "",
                                            "size": "col-12"
                                        }
                                    ]
                                },
                                {
                                    "textfieldapproval": false,
                                    "dropselect_display": false,
                                    "editcontent": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "eid": "s9",
                                    "statement": "In welcher Höhe ist Gesellschafter Output: Input Field  2 am Gesellschaftsvermögen am beteiligt?",
                                    "type": "textfield",
                                    "length": 1,
                                    "fields": [
                                        {
                                            "eid": "s9-ms1",
                                            "line": "",
                                            "value": "",
                                            "size": "col-12"
                                        }
                                    ]
                                },
                                {
                                    "tiptapedit": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "editcontent": false,
                                    "ishelp": false,
                                    "eid": "s10",
                                    "statement": "Sollen weitere Gesellschafter Teil des Vertrages ?",
                                    "type": "radio",
                                    "length": 2,
                                    "value": null,
                                    "fields": [
                                        {
                                            "tiptapedit": false,
                                            "eid": "s10-ms1",
                                            "value": "Ja",
                                            "line": "Ja"
                                        },
                                        {
                                            "tiptapedit": false,
                                            "eid": "s10-ms2",
                                            "value": "Nein",
                                            "line": "Nein"
                                        }
                                    ],
                                    "textfieldapproval": true,
                                    "textfieldapprovalfields": []
                                }
                            ]
                        },
                        {
                            "id": 6,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Geschäftsführung und Vertretung",
                            "name_edit": false,
                            "total_fields": 6,
                            "completed_fields": 0,
                            "linearprogressvalue": 0,
                            "isactive": false,
                            "havesomevalue": false,
                            "isedited": false,
                            "import_counter": 0,
                            "signaturecard": false,
                            "section_id_length": 1,
                            "section": [
                                {
                                    "eid": "s0",
                                    "statement": "Soll im Innenverhältnis die Erlaubnis beider Gesellschafter erforderlich sein?",
                                    "textfieldapproval": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "editcontent": false,
                                    "fields": []
                                },
                                {
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "editcontent": false,
                                    "eid": "s1",
                                    "statement": "Bei Ankauf, Verkauf und Belastung von Grundstücken für die Gesellschaft",
                                    "type": "radio",
                                    "length": 2,
                                    "value": null,
                                    "fields": [
                                        {
                                            "tiptapedit": false,
                                            "eid": "s1-ms1",
                                            "value": "Ja",
                                            "line": "Ja"
                                        },
                                        {
                                            "tiptapedit": false,
                                            "eid": "s1-ms2",
                                            "value": "Nein",
                                            "line": "Nein"
                                        }
                                    ],
                                    "textfieldapproval": false,
                                    "textfieldapprovalfields": []
                                },
                                {
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "editcontent": false,
                                    "eid": "s2",
                                    "statement": "Bei Abschluss von Miet- und Dienstverträgen jeglicher Art",
                                    "type": "radio",
                                    "length": 2,
                                    "value": null,
                                    "fields": [
                                        {
                                            "tiptapedit": false,
                                            "eid": "s2-ms1",
                                            "value": "Ja",
                                            "line": "Ja"
                                        },
                                        {
                                            "tiptapedit": false,
                                            "eid": "s2-ms2",
                                            "value": "Nein",
                                            "line": "Nein"
                                        }
                                    ],
                                    "textfieldapproval": false,
                                    "textfieldapprovalfields": []
                                },
                                {
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "editcontent": false,
                                    "eid": "s3",
                                    "statement": "Bei Abschluss von Verträgen die einen gewissen finanziellen Betrag übersteigen",
                                    "type": "radio",
                                    "length": 2,
                                    "value": null,
                                    "fields": [
                                        {
                                            "tiptapedit": false,
                                            "eid": "s3-ms1",
                                            "value": "Ja",
                                            "line": "Ja"
                                        },
                                        {
                                            "tiptapedit": false,
                                            "eid": "s3-ms2",
                                            "value": "Nein",
                                            "line": "Nein"
                                        }
                                    ],
                                    "textfieldapproval": false,
                                    "textfieldapprovalfields": []
                                },
                                {
                                    "eid": "s4",
                                    "statement": "Welcher Betrag in welcher Höhe soll im Einzelfall nicht überstiegen werden?",
                                    "textfieldapproval": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "editcontent": false,
                                    "fields": []
                                },
                                {
                                    "textfieldapproval": false,
                                    "dropselect_display": false,
                                    "editcontent": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "eid": "s5",
                                    "statement": "Wie hoch soll der Betrag sein, der im Einzelfall nicht überstiegen werden soll:€",
                                    "type": "textfield",
                                    "length": 1,
                                    "fields": [
                                        {
                                            "eid": "s5-ms1",
                                            "line": " € ",
                                            "value": "",
                                            "size": "col-12"
                                        }
                                    ]
                                },
                                {
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "editcontent": false,
                                    "eid": "s6",
                                    "statement": "Bei Aufnahme von Krediten und Übernahme von Bürgschaften;",
                                    "type": "radio",
                                    "length": 2,
                                    "value": null,
                                    "fields": [
                                        {
                                            "tiptapedit": false,
                                            "eid": "s6-ms1",
                                            "value": "Ja",
                                            "line": "Ja"
                                        },
                                        {
                                            "tiptapedit": false,
                                            "eid": "s6-ms2",
                                            "value": "Nein",
                                            "line": "Nein"
                                        }
                                    ],
                                    "textfieldapproval": false,
                                    "textfieldapprovalfields": []
                                },
                                {
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "editcontent": false,
                                    "eid": "s7",
                                    "statement": "Bei Aufnahme neuer Gesellschafter und Erhöhung der Einlagen",
                                    "type": "radio",
                                    "length": 2,
                                    "value": null,
                                    "fields": [
                                        {
                                            "tiptapedit": false,
                                            "eid": "s7-ms1",
                                            "value": "Ja",
                                            "line": "Ja"
                                        },
                                        {
                                            "tiptapedit": false,
                                            "eid": "s7-ms2",
                                            "value": "Nein",
                                            "line": "Nein"
                                        }
                                    ],
                                    "textfieldapproval": false,
                                    "textfieldapprovalfields": []
                                }
                            ]
                        },
                        {
                            "id": 7,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Pflichten der Gesellschafter",
                            "name_edit": false,
                            "total_fields": 2,
                            "completed_fields": 0,
                            "linearprogressvalue": 0,
                            "isactive": false,
                            "havesomevalue": false,
                            "isedited": false,
                            "import_counter": 0,
                            "signaturecard": false,
                            "section_id_length": 2,
                            "section": [
                                {
                                    "tiptapedit": false,
                                    "editcontent": false,
                                    "dropselect_display": false,
                                    "ishelp": false,
                                    "fields_display": true,
                                    "eid": "s1",
                                    "statement": "Soll für Zuwiderhandlungen eines Gesellschafters bei geschäftlich tätig werden ohne Einverständnis eines anderen Gesellschafters und / oder für die unmittelbare Beteiligung an Konkurrenzgeschäften eine Vertragsstrafe vereinbart werden?",
                                    "type": "radio",
                                    "length": 2,
                                    "value": "",
                                    "fields": [
                                        {
                                            "tiptapedit": false,
                                            "eid": "s1-ms1",
                                            "value": "Ja",
                                            "line": "Ja"
                                        },
                                        {
                                            "tiptapedit": false,
                                            "eid": "s1-ms2",
                                            "value": "Nein",
                                            "line": "Nein"
                                        }
                                    ],
                                    "textfieldapproval": true,
                                    "textfieldapprovalfields": []
                                },
                                {
                                    "textfieldapproval": false,
                                    "dropselect_display": false,
                                    "editcontent": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "eid": "s2",
                                    "statement": "Wie hoch soll diese Vertragsstrafe in Euro sein?",
                                    "type": "textfield",
                                    "length": 1,
                                    "fields": [
                                        {
                                            "eid": "s2-ms1",
                                            "line": " € ",
                                            "value": "",
                                            "size": "col-12"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "id": 8,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Gewinn- und Verlustrechnung / Entnahmerecht",
                            "name_edit": false,
                            "total_fields": 1,
                            "completed_fields": 0,
                            "linearprogressvalue": 0,
                            "isactive": false,
                            "havesomevalue": false,
                            "isedited": false,
                            "import_counter": 0,
                            "signaturecard": false,
                            "section_id_length": 2,
                            "section": [
                                {
                                    "eid": "s1",
                                    "statement": "Wie hoch soll die Vorabvergütung sein, die jedem Gesellschafter bezüglich der Beteiligung der Gesellschaft sein?",
                                    "type": "textfield",
                                    "length": 1,
                                    "textfieldapproval": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "editcontent": false,
                                    "fields": [
                                        {
                                            "eid": "s1-ms1",
                                            "line": "€",
                                            "value": "",
                                            "size": "col-12"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "id": 9,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Kündigung eines Gesellschafters",
                            "name_edit": false,
                            "total_fields": 1,
                            "completed_fields": 0,
                            "linearprogressvalue": 0,
                            "isactive": false,
                            "havesomevalue": false,
                            "isedited": false,
                            "import_counter": 0,
                            "signaturecard": false,
                            "section_id_length": 2,
                            "section": [
                                {
                                    "textfieldapproval": false,
                                    "editcontent": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "eid": "s1",
                                    "statement": "",
                                    "type": "radio",
                                    "length": 2,
                                    "value": "Ja",
                                    "fields": [
                                        {
                                            "tiptapedit": false,
                                            "eid": "s1-ms1",
                                            "value": "Ja",
                                            "line": "Ja"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "id": 10,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Tod eines Gesellschafters",
                            "name_edit": false,
                            "total_fields": 1,
                            "completed_fields": 0,
                            "linearprogressvalue": 0,
                            "isactive": false,
                            "havesomevalue": false,
                            "isedited": false,
                            "import_counter": 0,
                            "signaturecard": false,
                            "section_id_length": 2,
                            "section": [
                                {
                                    "textfieldapproval": false,
                                    "editcontent": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "eid": "s1",
                                    "statement": "",
                                    "type": "radio",
                                    "length": 2,
                                    "value": "Ja",
                                    "fields": [
                                        {
                                            "tiptapedit": false,
                                            "eid": "s1-ms1",
                                            "value": "Ja",
                                            "line": "Ja"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "id": 11,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Einsichtsrecht",
                            "name_edit": false,
                            "total_fields": 1,
                            "completed_fields": 0,
                            "linearprogressvalue": 0,
                            "isactive": false,
                            "havesomevalue": false,
                            "isedited": false,
                            "import_counter": 0,
                            "signaturecard": false,
                            "section_id_length": 2,
                            "section": [
                                {
                                    "textfieldapproval": false,
                                    "editcontent": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "eid": "s1",
                                    "statement": "",
                                    "type": "radio",
                                    "length": 2,
                                    "value": "Ja",
                                    "fields": [
                                        {
                                            "tiptapedit": false,
                                            "eid": "s1-ms1",
                                            "value": "Ja",
                                            "line": "Ja"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "id": 12,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Salvatorische Klausel",
                            "name_edit": false,
                            "total_fields": 1,
                            "completed_fields": 0,
                            "linearprogressvalue": 0,
                            "isactive": false,
                            "havesomevalue": false,
                            "isedited": false,
                            "import_counter": 0,
                            "signaturecard": false,
                            "section_id_length": 2,
                            "section": [
                                {
                                    "textfieldapproval": false,
                                    "editcontent": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "eid": "s1",
                                    "statement": "",
                                    "type": "radio",
                                    "length": 2,
                                    "value": "Ja",
                                    "fields": [
                                        {
                                            "tiptapedit": false,
                                            "eid": "s1-ms1",
                                            "value": "Ja",
                                            "line": "Ja"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "id": 13,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Änderungen des Vertrages",
                            "name_edit": false,
                            "total_fields": 4,
                            "completed_fields": 0,
                            "linearprogressvalue": 0,
                            "isactive": false,
                            "havesomevalue": false,
                            "isedited": false,
                            "import_counter": 0,
                            "section_id_length": 2,
                            "section": [
                                {
                                    "eid": "s0",
                                    "statement": "Gesellschafter [Input field 1]",
                                    "type": "textfield",
                                    "length": 4,
                                    "textfieldapproval": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "editcontent": false,
                                    "fields": []
                                },
                                {
                                    "eid": "s1",
                                    "statement": "Bitte geben Sie den Ort ein wo der Vertrag unterzeichnet wird:",
                                    "type": "textfield",
                                    "length": 1,
                                    "textfieldapproval": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "editcontent": false,
                                    "fields": [
                                        {
                                            "eid": "s1-ms1",
                                            "line": "",
                                            "value": "",
                                            "size": "col-12"
                                        }
                                    ]
                                },
                                {
                                    "eid": "s2",
                                    "statement": "Bitte geben Sie das aktuelle Datum ein:",
                                    "type": "textfield",
                                    "length": 1,
                                    "textfieldapproval": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "editcontent": false,
                                    "fields": [
                                        {
                                            "eid": "s2-ms1",
                                            "line": "",
                                            "value": "",
                                            "size": "col-12"
                                        }
                                    ]
                                },
                                {
                                    "eid": "s3",
                                    "statement": "Gesellschafter [Input field 2]",
                                    "type": "textfield",
                                    "length": 1,
                                    "textfieldapproval": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "editcontent": false,
                                    "fields": []
                                },
                                {
                                    "eid": "s4",
                                    "statement": "Bitte geben Sie den Ort ein wo der Vertrag unterzeichnet wird:",
                                    "type": "textfield",
                                    "length": 1,
                                    "textfieldapproval": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "editcontent": false,
                                    "fields": [
                                        {
                                            "eid": "s4-ms1",
                                            "line": "",
                                            "value": "",
                                            "size": "col-12"
                                        }
                                    ]
                                },
                                {
                                    "eid": "s5",
                                    "statement": "Bitte geben Sie das aktuelle Datum ein:",
                                    "type": "textfield",
                                    "length": 1,
                                    "textfieldapproval": false,
                                    "dropselect_display": false,
                                    "fields_display": true,
                                    "ishelp": false,
                                    "tiptapedit": false,
                                    "editcontent": false,
                                    "fields": [
                                        {
                                            "eid": "s5-ms1",
                                            "line": "",
                                            "value": "",
                                            "size": "col-12"
                                        }
                                    ]
                                }
                            ]
                        }
                        
                    ]
                },
                "rightSide": {
                    "title": "<h2>GbR Vertag</h2>",
                    "title_access": false,
                    "style": "text-align:center; font-weight: 700 ; font-size: 24px",
                    "card": [
                        {
                            "id": 1,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Vertragspartner",
                            "section_inner_value_counter_greater_than_one": false,
                            "section": [
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "<h2><strong>Vertragspartner</strong></h2>"
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        "s1-ms1",
                                        "s2-ms1",
                                        "s4-ms1",
                                        "s4-ms2",
                                        "s4-ms3",
                                        "s4-ms4",
                                        "s4-ms5",
                                        "s3-ms1"
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "text-align: center; font-weight: 700;font-size: 16px",
                                    "isStrong": true,
                                    "afterlinebreak": 0,
                                    "heading_property_number": true,
                                    "heading_value": 0,
                                    "tiptapedit": false,
                                    "contentedit": false,
                                    "inner_heading_property_number": false,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": ""
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [
                                                "s1-ms1"
                                            ],
                                            "display": "<strong> Zwischen </strong> <br /> [[0]]"
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        "s1-ms1"
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%",
                                    "isStrong": false,
                                    "afterlinebreak": 0,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "tiptapedit": false,
                                    "contentedit": false,
                                    "inner_heading_property_number": false,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": ""
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [
                                                "s2-ms1"
                                            ],
                                            "display": " [[0]]"
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        "s2-ms1"
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%",
                                    "isStrong": false,
                                    "afterlinebreak": 0,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "tiptapedit": false,
                                    "contentedit": false,
                                    "inner_heading_property_number": false,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": ""
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [
                                                "s3-ms1"
                                            ],
                                            "display": " [[0]] <br /> <strong> (Gesellschafter)</strong>"
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        "s3-ms1"
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%",
                                    "isStrong": false,
                                    "afterlinebreak": 0,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "tiptapedit": false,
                                    "contentedit": false,
                                    "inner_heading_property_number": false,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": ""
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [
                                                "s4-ms3",
                                                "s4-ms4",
                                                "s4-ms5"
                                            ],
                                            "display": "<strong>Gelesafter 2 </strong>  [[0]] [[1]] [[2]]"
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s4": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                }
                            ]
                        },
                        {
                            "id": 2,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Name, Sitz und Zweck der Gesellschaft",
                            "section_inner_value_counter_greater_than_one": false,
                            "section": [
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "<h2><strong>Name, Sitz und Zweck der Gesellschaft</strong><h2>"
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s1": "Ja"
                                        },
                                        {
                                            "s1": "Nein"
                                        },
                                        {
                                            "s2": "Ja"
                                        },
                                        {
                                            "s2": "Nein"
                                        },
                                        {
                                            "s3": "Ja"
                                        },
                                        {
                                            "s3": "Nein"
                                        },
                                        {
                                            "s4": "Sprache wählen"
                                        },
                                        {
                                            "s4": "Keine weitere Sprache"
                                        },
                                        {
                                            "s5": "Ja"
                                        },
                                        {
                                            "s5": "Nein"
                                        },
                                        "s6-ms1",
                                        "s7-ms1",
                                        {
                                            "s8": "Berichtigung im Formular möglich, Abbruch der Bestellung durch Schließen des Browsers"
                                        },
                                        {
                                            "s8": "Sonstiges"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "text-align: center; font-weight: 700;font-size: 16px",
                                    "isStrong": true,
                                    "afterlinebreak": 0,
                                    "heading_property_number": true,
                                    "heading_value": 0,
                                    "tiptapedit": false,
                                    "contentedit": false,
                                    "inner_heading_property_number": false,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": ""
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [
                                                "s6-ms1"
                                            ],
                                            "display": "Von den Unterzeichnern wird eine Gesellschaft bürgerlichen Rechts unter der Bezeichnung: [[0]]"
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        "s6-ms1"
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [
                                                "s7-ms1"
                                            ],
                                            "display": "im Geschäftsbereich (Zweck der Gesellschaft) [[0]] gegründet.  <br />Die Gesellschaft ist auf alle diesem Zweck förderlichen Maßnahmen und Rechtsgeschäfte gerichtet "
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        "s7-ms1"
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "Es können Filialen gegründet werden."
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s5": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5% display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "Es können keine Filialen gegründet werden."
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s5": "Nein"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [
                                                "s1-ms1"
                                            ],
                                            "display": " Sitz der Gesellschaft ist [[0]]."
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        "s1-ms1"
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                }
                            ]
                        },
                        {
                            "id": 3,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Dauer der Gesellschaft",
                            "section_inner_value_counter_greater_than_one": false,
                            "section": [
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "<h2><strong>Dauer der Gesellschaft</strong></h2>"
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        "s1-ms1",
                                        {
                                            "s2": "Keine Besonderheiten [Angebote gegenüber Verbrauchern]"
                                        },
                                        {
                                            "s2": "Kleinunternehmer iSd § 19 UstG"
                                        },
                                        {
                                            "s3": "Ja"
                                        },
                                        {
                                            "s3": "Nein"
                                        },
                                        {
                                            "s4": "Ja"
                                        },
                                        {
                                            "s4": "Nein"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "text-align: center; font-weight: 700;font-size: 16px",
                                    "isStrong": true,
                                    "afterlinebreak": 0,
                                    "heading_property_number": true,
                                    "heading_value": 0,
                                    "tiptapedit": false,
                                    "contentedit": false,
                                    "inner_heading_property_number": false,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": ""
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [
                                                "s1-ms1"
                                            ],
                                            "display": " Die Gesellschaft beginnt am [[0]] und wird auf unbestimmte Zeit geschlossen. <br /> Der Gesellschaftsvertrag kann unter Einhaltung einer Frist von sechs Monaten jeweils zum Schluss eines Kalenderjahres gekündigt werden. <br /> Die Kündigung muss schriftlich in Textform erfolgen."
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        "s1-ms1"
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                }
                            ]
                        },
                        {
                            "id": 4,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Geschäftsjahr",
                            "section_inner_value_counter_greater_than_one": false,
                            "section": [
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "<h2><strong>Geschäftsjahr</strong></h2>"
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s1": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "text-align: center; font-weight: 700;font-size: 16px",
                                    "isStrong": true,
                                    "afterlinebreak": 0,
                                    "heading_property_number": true,
                                    "heading_value": 0,
                                    "tiptapedit": false,
                                    "contentedit": false,
                                    "inner_heading_property_number": false,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": ""
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "Das Geschäftsjahr entspricht dem Kalenderjahr."
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s1": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                }
                            ]
                        },
                        {
                            "id": 5,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Einlagen der Gesellschafter",
                            "section_inner_value_counter_greater_than_one": false,
                            "section": [
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "<h2><strong>Einlagen der Gesellschafter</strong></h2>"
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s1": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "text-align: center; font-weight: 700;font-size: 16px",
                                    "isStrong": true,
                                    "afterlinebreak": 0,
                                    "heading_property_number": true,
                                    "heading_value": 0,
                                    "contentedit": false,
                                    "tiptapedit": false,
                                    "inner_heading_property_number": false,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": ""
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [
                                                "s4-ms3"
                                            ],
                                            "display": "bringt in bar [[0]] € in die Gesellschaft ein."
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s4": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [
                                                "s5-ms4",
                                                "s5-ms5"
                                            ],
                                            "display": "bringt [[0]] im Wert von [[1]] € ein."
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s5": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [
                                                "s6-ms3"
                                            ],
                                            "display": "bringt in bar [[0]] € in die Gesellschaft ein."
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s6": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [
                                                "s7-ms4",
                                                "s7-ms5"
                                            ],
                                            "display": "bringt [[0]] im Wert von [[1]] € ein."
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s7": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [
                                                "s8-ms1"
                                            ],
                                            "display": " Gesellschafter Output: Input Field  1 ist in Höhe von [[0]] € am Gesellschaftsvermögen am beteiligt."
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        "s8-ms1"
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [
                                                "s9-ms1"
                                            ],
                                            "display": " Gesellschafter Output: Input Field  2 ist in Höhe von [[0]] € am Gesellschaftsvermögen am beteiligt."
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        "s9-ms1"
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                }
                            ]
                        },
                        {
                            "id": 6,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Geschäftsführung und Vertretung",
                            "section_inner_value_counter_greater_than_one": false,
                            "section": [
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "<h2><strong>Geschäftsführung und Vertretung </strong> </h2> <br /> Zur Geschäftsführung und Vertretung sind die Gesellschafter gemeinschaftlich berechtigt.Jeder Gesellschafter ist zur Geschäftsführung alleine berechtigt. Er vertritt die Gesellschaft im Außenverhältnis allein. <br /> <br /> Im Innenverhältnis ist die Zustimmung beider Gesellschafter zu nachfolgenden Rechtshandlungen und Rechtsgeschäften erforderlich: "
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s1": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "text-align: center; font-weight: 700;font-size: 16px",
                                    "isStrong": true,
                                    "afterlinebreak": 0,
                                    "heading_property_number": true,
                                    "heading_value": 0,
                                    "tiptapedit": false,
                                    "contentedit": false,
                                    "inner_heading_property_number": false,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": ""
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "⦁ Ankauf, Verkauf und Belastung von Grundstücken;"
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s1": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "⦁ Abschluss von Miet- und Dienstverträgen jeglicher Art;"
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s2": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [
                                                "s5-ms1"
                                            ],
                                            "display": "⦁  Abschluss von Verträgen, deren Wert im Einzelfall den Betrag von [[0]] € übersteigt;"
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s3": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "⦁    Aufnahme von Krediten und Übernahme von Bürgschaften;"
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s6": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "⦁    Aufnahme neuer Gesellschafter und Erhöhung der Einlagen."
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s7": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                }
                            ]
                        },
                        {
                            "id": 7,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Pflichten der Gesellschafter",
                            "section_inner_value_counter_greater_than_one": false,
                            "section": [
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "<h2><strong>Pflichten der Gesellschafter</strong></h2> <br /> Keiner der Gesellschafter darf ohne schriftliches Einverständnis des anderen Gesellschafters außerhalb der Gesellschaft ohne Rücksicht auf die jeweilige Branche geschäftlich tätig werden. Dazu gehört auch eine mittelbare oder unmittelbare Beteiligung an Konkurrenzgeschäften."
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s1": "Ja"
                                        },
                                        {
                                            "s2": "Ja"
                                        },
                                        {
                                            "s2": "Nein"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "text-align: center; font-weight: 700;font-size: 16px",
                                    "isStrong": true,
                                    "afterlinebreak": 0,
                                    "heading_property_number": true,
                                    "heading_value": 0,
                                    "tiptapedit": false,
                                    "contentedit": false,
                                    "inner_heading_property_number": false,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": ""
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [
                                                "s2-ms1"
                                            ],
                                            "display": "Für Zuwiderhandlungen wird eine Vertragsstrafe in Höhe von je [[0]] €  . € vereinbart."
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s1": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "Die fristlose Kündigung bleibt vorbehalten. <br /> Jeder Gesellschafter kann verlangen, dass der jeweils andere Gesellschafter alle auf eigene Rechnung abgeschlossenen Geschäfte als für die Gesellschaft eingegangen gelten lässt. <br /> Daraus folgt, dass die aus solchen Geschäften bezogenen Vergütungen herauszugeben sind oder die Ansprüche auf Vergütung an die Gesellschaft abgetreten werden müssen. "
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s1": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "text-align: center; font-weight: 700;font-size: 16px",
                                    "isStrong": true,
                                    "afterlinebreak": 0,
                                    "heading_property_number": true,
                                    "heading_value": 0,
                                    "tiptapedit": false,
                                    "contentedit": false,
                                    "inner_heading_property_number": false,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": ""
                                }
                            ]
                        },
                        {
                            "id": 8,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Gewinn- und Verlustrechnung / Entnahmerecht",
                            "section_inner_value_counter_greater_than_one": false,
                            "section": [
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "<h2><strong>Gewinn- und Verlustrechnung / Entnahmerecht</strong></h2> "
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s1": "Ja"
                                        },
                                        {
                                            "s2": "Ja"
                                        },
                                        {
                                            "s2": "Nein"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "text-align: center; font-weight: 700;font-size: 16px",
                                    "isStrong": true,
                                    "afterlinebreak": 0,
                                    "heading_property_number": true,
                                    "heading_value": 0,
                                    "tiptapedit": false,
                                    "contentedit": false,
                                    "inner_heading_property_number": false,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": ""
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [
                                                "s1-ms1"
                                            ],
                                            "display": "Gewinn und Verlust der Gesellschaft werden nach Maßgabe der Beteiligung der Gesellschafter aufgeteilt. Jedem Gesellschafter steht eine Vorabvergütung in Höhe von [[0]] € zu. <br /> Sollte die Gesellschaft nach Feststellung des Jahresabschlusses durch Auszahlung der Vorabvergütung in die Verlustzone geraten, sind die Gesellschafter zu entsprechendem Ausgleich verpflichtet."
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        "s1-ms1"
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: center; font-weight: 700; font-size: 18.72px",
                                    "isStrong": true,
                                    "afterlinebreak": 0,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false,
                                    "inner_heading_property_number": false,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": ""
                                }
                            ]
                        },
                        {
                            "id": 9,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Kündigung eines Gesellschafters",
                            "section": [
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "<h2><strong>Kündigung eines Gesellschafters</strong></h2> "
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s1": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "text-align: center; font-weight: 700;font-size: 16px",
                                    "isStrong": true,
                                    "afterlinebreak": 0,
                                    "heading_property_number": true,
                                    "heading_value": 0,
                                    "tiptapedit": false,
                                    "contentedit": false,
                                    "inner_heading_property_number": false,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": ""
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "Im Falle der Kündigung scheidet der kündigende Gesellschafter aus der Gesellschaft aus. Der verbleibende Gesellschafter ist berechtigt, das Unternehmen mit Aktiva und Passiva unter Ausschluss der Liquidation zu übernehmen und fortzuführen. <br /> Dem ausscheidenden Gesellschafter ist das Auseinandersetzungsguthaben auszuzahlen. <br /> Bei der Feststellung des Auseinandersetzungsguthabens sind Aktiva und Passiva mit ihrem wahren Wert einzusetzen. Der Geschäftswert ist nicht zu berücksichtigen. <br /> Die Auszahlung des Auseinandersetzungsguthabens hat in vier gleichen Vierteljahresraten zu erfolgen, von denen die erste drei Monate nach dem Ausscheiden fällig ist. <br /> <br /> Das Auseinandersetzungsguthaben ist ab dem Ausscheidungszeitpunkt in Höhe des jeweiligen Hauptrefinanzierungssatzes der Europäischen Zentralbank zu verzinsen. "
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s1": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                }
                            ]
                        },
                        {
                            "id": 10,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Tod eines Gesellschafters",
                            "section": [
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "<h2><strong>Tod eines Gesellschafters</strong></h2> "
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s1": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "text-align: center; font-weight: 700;font-size: 16px",
                                    "isStrong": true,
                                    "afterlinebreak": 0,
                                    "heading_property_number": true,
                                    "heading_value": 0,
                                    "tiptapedit": false,
                                    "contentedit": false,
                                    "inner_heading_property_number": false,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": ""
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "Im Falle des Todes eines Gesellschafters gilt § 8 entsprechend mit der Maßgabe, dass die Auseinandersetzungsbilanz zum Todestag aufzustellen ist. "
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s1": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                }
                            ]
                        },
                        {
                            "id": 11,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Einsichtsrecht",
                            "section": [
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "<h2><strong>Einsichtsrecht</strong></h2> "
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s1": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "text-align: center; font-weight: 700;font-size: 16px",
                                    "isStrong": true,
                                    "afterlinebreak": 0,
                                    "heading_property_number": true,
                                    "heading_value": 0,
                                    "tiptapedit": false,
                                    "contentedit": false,
                                    "inner_heading_property_number": false,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": ""
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "Jeder Gesellschafter ist berechtigt, sich über die Angelegenheiten der Gesellschaft durch Einsicht in die Geschäftsbücher und Papiere zu unterrichten und sich aus ihnen eine Übersicht über den Stand des Gesellschaftsvermögens anzufertigen. <br /> Jeder Gesellschafter kann auf eigene Kosten einen zur Berufsverschwiegenheit verpflichteten Dritten bei der Wahrnehmung dieser Rechte hinzuziehen oder zur Wahrnehmung dieser Rechte beauftragen."
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s1": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                }
                            ]
                        },
                        {
                            "id": 12,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Salvatorische Klausel",
                            "section": [
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "<h2><strong>Salvatorische Klausel</strong></h2> "
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s1": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "text-align: center; font-weight: 700;font-size: 16px",
                                    "isStrong": true,
                                    "afterlinebreak": 0,
                                    "heading_property_number": true,
                                    "heading_value": 0,
                                    "tiptapedit": false,
                                    "contentedit": false,
                                    "inner_heading_property_number": false,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": ""
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "Sollte eine Bestimmung dieses Vertrages unwirksam sein, so bleibt der Vertrag im Übrigen wirksam. <br /> Für den Fall der Unwirksamkeit verpflichten sich die Gesellschafter, eine neue Regelung zu treffen, die wirtschaftlich der unwirksamen Regelung weitestgehend entspricht."
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s1": "Ja"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: justify; margin-top: 0.5%; display:inline;",
                                    "isStrong": false,
                                    "afterlinebreak": 1,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "inner_heading_property_number": true,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": "",
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false
                                }
                            ]
                        },
                        {
                            "id": 13,
                            "eye": false,
                            "isClicked" :false,
                            "name": "Änderungen des Vertrages",
                            "type": "signature_card",
                            "section_inner_value_counter_greater_than_one": false,
                            "section": [
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [],
                                            "display": "<h2><strong>Änderungen des Vertrages</strong></h2> <br /> Änderungen und Ergänzungen dieses Vertrages bedürfen der Schriftform. "
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        {
                                            "s1": "Ja"
                                        },
                                        {
                                            "s2": "Ja"
                                        },
                                        {
                                            "s2": "Nein"
                                        }
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "text-align: center; font-weight: 700;font-size: 16px",
                                    "isStrong": true,
                                    "afterlinebreak": 0,
                                    "heading_property_number": true,
                                    "heading_value": 0,
                                    "tiptapedit": false,
                                    "contentedit": false,
                                    "inner_heading_property_number": false,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": ""
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [
                                                "s1-ms1"
                                            ],
                                            "display": "[[0]]"
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        "s1-ms1"
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: center; font-weight: 700; font-size: 18.72px",
                                    "isStrong": true,
                                    "afterlinebreak": 0,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false,
                                    "inner_heading_property_number": false,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": ""
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [
                                                "s2-ms1"
                                            ],
                                            "display": "[[0]]        Unterschrift  <br /> <br />"
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        "s2-ms1"
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: center; font-weight: 700; font-size: 18.72px",
                                    "isStrong": true,
                                    "afterlinebreak": 0,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false,
                                    "inner_heading_property_number": false,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": ""
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [
                                                "s4-ms1"
                                            ],
                                            "display": "[[0]]"
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        "s4-ms1"
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: center; font-weight: 700; font-size: 18.72px",
                                    "isStrong": true,
                                    "afterlinebreak": 0,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false,
                                    "inner_heading_property_number": false,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": ""
                                },
                                {
                                    "if": true,
                                    "type": "or",
                                    "dependent_values": [
                                        {
                                            "depend_upon": [
                                                "s5-ms1"
                                            ],
                                            "display": "[[0]]        Unterschrift"
                                        }
                                    ],
                                    "values_on_which_depend": [
                                        "s5-ms1"
                                    ],
                                    "value": "",
                                    "display": "",
                                    "style": "word-break: break-word;text-align: center; font-weight: 700; font-size: 18.72px",
                                    "isStrong": true,
                                    "afterlinebreak": 0,
                                    "heading_property_number": false,
                                    "heading_value": 0,
                                    "displayed": false,
                                    "tiptapedit": false,
                                    "contentedit": false,
                                    "inner_heading_property_number": false,
                                    "inner_heading_value": 0,
                                    "inner_heading_value_display": ""
                                }
                            ]
                        }
                    ]
                }
            },
            "image": "https://media.istockphoto.com/vectors/signed-and-stamped-contract-paper-icon-vector-id628885590?k=20&m=628885590&s=170667a&w=0&h=YaqWqDImM2EZsWSQT7Kka9zIz61HMnAPB7IZjRhAk3g=",
            "id": 1
        }
    ]
}