import React from "react";
import "./App.css";
import { workspaceDemo } from "./store/demoWorkspace";
import CC from "./contractPanel/contract";

import { data } from "./store/data"
import { useEffect } from "react";

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import DocumentPreview from "./documentPreview/documentPreview";


function App() {
  useEffect(() => {
    localStorage.setItem("Data", JSON.stringify(data));
    let datas = JSON.parse(localStorage.getItem("Data") || "{}");
  });

  return (
    <div className="App" >



      <BrowserRouter>

        <Switch>
          <Route exact path="/">
            <CC admin={false} cname="GbR Vertag" cid={1} workspace={workspaceDemo} />
          </Route>

        </Switch>
        <Switch>
          <Route exact path="/preview">
            <DocumentPreview admin={false} cname="GbR Vertag" cid={1} workspace={workspaceDemo} />
          </Route>

        </Switch>
      </BrowserRouter>




    </div>

  );
}

export default App;
