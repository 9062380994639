import Contracts from "./contractsPanel";
// import { useNotify } from "react-admin";
import { useEffect } from "react";
import { workspaceDemo } from "../store/demoWorkspace";
import { locald } from "../store/localStorage";
interface Props {
  admin: any;
  workspace: any;
  cname: any;
  cid: any;
}
const CC = ({ admin, cname, workspace, cid }: Props) => {
  useEffect(() => {
    let datas = JSON.parse(localStorage.getItem("Data") || "{}");
    // console.log("datagroupppppppppppppp",datas)

  });
  // console.log(workspace);
  // console.log("localD",locald)

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const notify = useNotify();
  const today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  let datas = JSON.parse(localStorage.getItem("Data") || "{}");
  let array = workspace
    .contracts.filter((d: any) => d.id === cid);
  let rightArr = array[0].document.rightSide.card;
  // console.log('rightARR ',rightArr)

  let thisContract = rightArr.filter(
    (r: any) => r.type === "signature_card"
  )[0];

  const expiry = 'null';
  if (expiry === date) {
    // console.log("expiry notify here", date);
    // notify(` ${cname} Contract is going to expire today! `, "info", {}, false);
  }
  return (
    <div className="verScroll">
      <Contracts admin={admin} cname={cname} cid={cid} workspace={workspace} />
    </div>
  );
};

export default CC;
